import React, { useEffect, useState } from 'react';
import AlertModalWithButtons from '../../global/atoms/AlertModalWithButtons/AlertModalWithButtons';
import { useCartState } from '../../../contexts/cart';
import { checkoutDatalocator } from '../checkoutAndOrderSummary/dataLocators';
import useCheckout from '../../../hooks/useCheckout';
import { useCheckUser } from '../../../hooks/useCheckUser';
import { useIntl } from 'react-intl';
import { CHECKOUT_ALERT_TYPE_DATE, CHECKOUT_ALERT_TYPE_TIME } from '../constants';
import { getTimeFromCity } from '../../global/utils/commonUtils';
import { useFilterState } from '../../cap';
import config from '../../App/config';
import { SET_END_DATE, SET_START_DATE } from '../../cap/constants';
import { STORAGE_CONFIG } from '../../../constants/storageConfig';
import { hasDateEllapsed, hasTimeEllapsed } from '../../checkoutv2/utils/editOrderDetailsFunctions';
import { USER_TYPE } from '../../../constants/userDetailsConstants';

const ElapsedTimeDateAlert = props => {
    const [{ howToGetYourOrderDetails, timeZoneID, cart }, dispatch] = useCartState();
    const [{ viewCart, projectDetails, startDate, endDate }, filterDispatch] = useFilterState();
    const { handleStepChange, formStep, currentStep } = props;
    const [alertType, setAlertType] = useState('DATE');
    const [showAlertModal, setShowAlertModal] = useState(false);
    const { clearCheckout } = useCheckout();
    const userType = useCheckUser();
    const intl = useIntl();

    const onClose = () => {
        setShowAlertModal(false);
        if (alertType === CHECKOUT_ALERT_TYPE_DATE) {
            filterDispatch({ type: SET_START_DATE, startDate: '' });
            filterDispatch({ type: SET_END_DATE, endDate: '' });
            localStorage.removeItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE);
            localStorage.removeItem(STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE);
        }
        sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.IS_CAP_IN_EDIT_MODE, true);
        window.location.href = window.location.origin + config.pagePaths.cartPage;

    };
    useEffect(() => {
        checkEllapsedConditions();
    }, [currentStep]);
    const checkEllapsedConditions = () => {
        const viewCartObj = {
            startDate,
            endDate,
            isInStorePickup: viewCart?.isInStorePickup
        };
        if (currentStep > 1) {
            let currentOffSet = getTimeFromCity(timeZoneID);
            if (hasDateEllapsed(currentOffSet, startDate)) {
                setAlertType(CHECKOUT_ALERT_TYPE_DATE);
                setShowAlertModal(true);
            } else if ((userType !== USER_TYPE.GUEST && currentStep > 2) || currentStep > 3) {
                if (hasTimeEllapsed(viewCartObj, howToGetYourOrderDetails, timeZoneID, cart)) {
                    setAlertType(CHECKOUT_ALERT_TYPE_TIME);
                    setShowAlertModal(true);
                }
            }
        }
    };
    const alertText =
        alertType === CHECKOUT_ALERT_TYPE_DATE
            ? intl.formatMessage({ id: 'checkout:elapsed-date-alert-text' })
            : intl.formatMessage({ id: 'checkout:elapsed-time-alert-text' });

    const alertTitle =
        alertType === 'DATE'
            ? intl.formatMessage({ id: 'checkout:elapsed-date-alert-title' })
            : intl.formatMessage({ id: 'checkout:elapsed-time-alert-title' });

    return (
        <>
            {' '}
            {showAlertModal && (
                <AlertModalWithButtons
                    isOpen={showAlertModal}
                    title={alertTitle}
                    warningQuestion={alertText}
                    handleContinueClick={onClose}
                    continueButtonText={intl.formatMessage({ id: 'checkout:continue-cta' })}
                    handleCancelClick={onClose}
                    closeIconDataLocator={checkoutDatalocator.checkout_cross_icon}
                    continueButtonDataLocator={checkoutDatalocator.checkout_continue_cta_testid}
                    titleDataLocator={
                        alertType === CHECKOUT_ALERT_TYPE_DATE
                            ? checkoutDatalocator.checkout_elapsed_date_title
                            : checkoutDatalocator.checkout_elapsed_time_title
                    }
                    warningQuestionDataLocator={
                        alertType === CHECKOUT_ALERT_TYPE_DATE
                            ? checkoutDatalocator.checkout_elapsed_date_text
                            : checkoutDatalocator.checkout_elapsed_time_text
                    }
                />
            )}
        </>
    );
};

export default ElapsedTimeDateAlert;
