import React, { useState } from 'react';
import { useCheckAuthorityType } from '../../../../hooks/useCheckUser';
import { useCartState } from '../../../../contexts/cart';
import Accessories from './Accessories';
import AccessoryItemDesc from './AccessoryItemDesc';
import Addons from './Addons';
import AddonsForPurchase from './AddonsForPurchase';
import '../accessoriesAndAddons.scss';
import { ENV_CONFIG } from '../../../../constants/envConfig';
import { FORCE_ITEM } from '../../constants';

const RentalEquipment = ({ selectedItems, addAccessory, addAddon }) => {
    const [{ consumables }] = useCartState();
    const [isAddonViewAll, setIsAddonViewAll] = useState(false);
    const maxAddOnsLimit = parseInt(ENV_CONFIG.MAXADDONS);

    let currentCount = 0;
    const renderItem = (item, i) => {
        const filteredSpecGroups = item?.specGroups?.reduce((acc, item) => {
            const filteredItems = item?.items?.filter(item => item.forceItem !== FORCE_ITEM.YES);
            if (filteredItems.length > 0) {
                acc.push({
                    ...item,
                    items: filteredItems
                });
            }
            return acc;
        }, []);
        
        return (
            <>
                {filteredSpecGroups?.map((consumableChild, index) => {
                    if (currentCount < maxAddOnsLimit || isAddonViewAll) {
                        currentCount++;

                        return (
                            <div className="rental-addon-container" key={index.toString()}>
                                <AddonsForPurchase
                                    selectedItems={selectedItems}
                                    consumableGroup={consumableChild}
                                    addAddon={addAddon}></AddonsForPurchase>
                            </div>
                        );
                    }
                })}
            </>
        );
    };

    const renderAddons = () => {
        let isAddonsAvailable = false;
        const addOns = consumables?.selectedRentalEquipment?.equipmentItems?.consumables?.reduce((acc, item) => {
            const filteredSpecGroups = item.specGroups
                .map(specGroup => {
                    const filteredItems = specGroup.items.filter(item => item.forceItem !== FORCE_ITEM.YES);
                    return {
                        ...specGroup,
                        items: filteredItems
                    };
                })
                .filter(specGroup => specGroup.items.length > 0);
            if (filteredSpecGroups.length > 0) {
                acc.push({
                    ...item,
                    specGroups: filteredSpecGroups
                });
            }
            return acc;
        }, []);
        
        for (let index = 0; index < addOns?.length; index++) {
            if (addOns[index]?.specGroups?.length > 0) {
                isAddonsAvailable = true;
                break;
            }
        }
        return (
            <>
                {isAddonsAvailable && (
                    <Addons consumables={consumables} renderItem={renderItem} setIsAddonViewAll={setIsAddonViewAll} />
                )}
            </>
        );
    };

    const renderAccessories = () => {
        const rentables = consumables?.selectedRentalEquipment?.equipmentItems?.rentables?.reduce((acc, item) => {
            if (item?.forceItem !== FORCE_ITEM.YES) {
                acc.push(item);
            }
            return acc;
        }, []);
        
        return (
            <>
                {rentables?.length > 0 && (
                    <Accessories rentables={rentables} selectedItems={selectedItems} addAccessory={addAccessory} />
                )}
            </>
        );
    };

    return (
        <>
            <div className="modal-content-fixed-body modal-rental-body modal-rental-body__checkoutv2">
                <div className="accessory-container accessory-container--wrap">
                    <AccessoryItemDesc
                        title={consumables?.selectedRentalEquipment?.equipmentInfo?.name}
                        imgSrc={consumables?.selectedRentalEquipment?.equipmentInfo?.img}
                        quantity={consumables?.selectedRentalEquipment?.equipmentInfo?.qty}></AccessoryItemDesc>
                </div>
                <div className="accessory-addon-container">
                    {renderAccessories()}
                    {renderAddons()}
                </div>
            </div>
        </>
    );
};

export default RentalEquipment;
