import React from 'react';
import { func, number, object, string, bool } from 'prop-types';
import OrderSummaryLineItem from '../orderSummaryLineItem/OrderSummaryLineItem';
import Alert from '../../../../global/atoms/alert/alert';
import OrderSummaryShimmer from '../orderSummaryShimmer/OrderSummaryShimmer';
import { checkoutOrderSummaryDataLocators } from '../../dataLocators';
import RoundTripFee from '../roundTripFee/RoundTripFee';
import { useCheckAuthorityType } from '../../../../../hooks/useCheckUser';
import { AUTHORITY_TYPE } from '../../../../global/constants';
import '../../orderSummaryFactory.scss';

const CheckoutSummaryView = props => {
    const {
        orderSummaryIntl,
        orderTotals,
        currentStep,
        formStep,
        source,
        deliveryChargeFlag,
        handleRoundTripAndEstimateFlow,
        isRatesFromTier2Radius,
        isDeliveryFeeRequired,
        isDelivery,
        shouldShowTbdAlert,
        isInStorePickupFromSessionStorage,
        showShimmer,
        variant
    } = props;
    const authorityType = useCheckAuthorityType();

    const {
        rentalSubtotal,
        purchaseSubtotal,
        rentalProtectionPlan,
        prepayFuelOption,
        deliveryPickUpCharges,
        estimatedSubtotal,
        envFees,
        otherFees,
        taxes,
        subtotalValue,
        showDeliveryEstimate,
        dispatch
    } = orderTotals;
    const itemLabel = authorityType === AUTHORITY_TYPE.P2P ? 'order-summary:total' : 'order-summary:estimated-total';

    if (showShimmer) {
        return <OrderSummaryShimmer variant={variant}/>;
    }

    return (
        <>
            <div className="ordersummary">
                <div className={'ordersummary-top-checkout'}>
                    {currentStep > formStep?.OPTIONAL_PLANS ? null : (
                        <div
                            data-testid={checkoutOrderSummaryDataLocators.miniCartOverlay_taxesFees_txt}
                            className="ordersummary-top-content">
                            {orderSummaryIntl.formatMessage({ id: 'order-summary:tax-and-fees-warning-text' })}
                        </div>
                    )}
                </div>
                <div className={'ordersummary-items-checkout'}>
                    <OrderSummaryLineItem
                        itemLabel={orderSummaryIntl.formatMessage({ id: 'order-summary:rental-subtotal' })}
                        itemLabelDataTestId={checkoutOrderSummaryDataLocators.checkoutOrderSummary_rentalSubtotal_lbl}
                        isTooltipIcon={false}
                        itemValue={rentalSubtotal}
                        itemValueDataTestId={
                            checkoutOrderSummaryDataLocators.checkoutOrderSummary_rentalSubtotal_dynvalue
                        }
                        dispatch={dispatch}
                    />
                    {
                        <OrderSummaryLineItem
                            itemLabel={orderSummaryIntl.formatMessage({ id: 'order-summary:purchases-subtotal' })}
                            itemLabelDataTestId={
                                checkoutOrderSummaryDataLocators.checkoutOrderSummary_purchase_subtotal_lbl
                            }
                            isTooltipIcon={false}
                            itemValue={purchaseSubtotal}
                            itemValueDataTestId={
                                checkoutOrderSummaryDataLocators.checkoutOrderSummary_purchase_subtotal_dynValue
                            }
                            dispatch={dispatch}
                        />
                    }
                    {isInStorePickupFromSessionStorage ? (
                        <OrderSummaryLineItem // need to confirm styling while testing
                            itemLabel={orderSummaryIntl.formatMessage({ id: 'order-summary:instore-pickup' })}
                            itemLabelDataTestId={
                                checkoutOrderSummaryDataLocators.checkoutOrderSummary_instorePickup_lbl
                            }
                            isTooltipIcon
                            tooltipIconMessage={orderSummaryIntl.formatMessage({ id: 'order-summary:instore-pickup' })}
                            itemValue={orderSummaryIntl.formatMessage({ id: 'order-summary:instore-pickup-value' })}
                            itemValueDataTestId={
                                checkoutOrderSummaryDataLocators.checkoutOrderSummary_instorePickupFree_txt
                            }
                            dispatch={dispatch}
                        />
                    ) : (
                        <RoundTripFee
                            source={source}
                            deliveryChargeFlag={deliveryChargeFlag}
                            deliveryPickUpCharges={deliveryPickUpCharges}
                            showDeliveryEstimate={showDeliveryEstimate}
                            handleRoundTripAndEstimateFlow={handleRoundTripAndEstimateFlow}
                            isRatesFromTier2Radius={isRatesFromTier2Radius}
                            isDeliveryFeeRequired={isDeliveryFeeRequired}
                            isDelivery={isDelivery}
                            dispatch={dispatch}></RoundTripFee>
                    )}
                    <OrderSummaryLineItem
                        itemLabel={orderSummaryIntl.formatMessage({ id: 'order-summary:rental-protection-plan' })}
                        itemLabelDataTestId={checkoutOrderSummaryDataLocators.checkoutOrderSummary_rpp_lbl}
                        isTooltipIcon
                        tooltipIconMessage={orderSummaryIntl.formatMessage({
                            id: 'order-summary:rental-protection-plan'
                        })}
                        itemValue={rentalProtectionPlan}
                        itemValueDataTestId={checkoutOrderSummaryDataLocators.checkoutOrderSummary_rppBlank_value}
                        dispatch={dispatch}
                    />
                    <OrderSummaryLineItem
                        itemLabel={orderSummaryIntl.formatMessage({ id: 'order-summary:fuel-convenience-plan' })}
                        itemLabelDataTestId={checkoutOrderSummaryDataLocators.checkoutOrderSummary_fcp_lbl}
                        isTooltipIcon
                        tooltipIconMessage={orderSummaryIntl.formatMessage({
                            id: 'order-summary:fuel-convenience-plan'
                        })}
                        itemValue={prepayFuelOption}
                        itemValueDataTestId={checkoutOrderSummaryDataLocators.checkoutOrderSummary_fcpBlank_value}
                        dispatch={dispatch}
                    />
                    <OrderSummaryLineItem
                        itemLabel={orderSummaryIntl.formatMessage({ id: 'order-summary:environment-fee' })}
                        itemLabelDataTestId={checkoutOrderSummaryDataLocators.checkoutOrderSummary_srp_lbl}
                        isTooltipIcon
                        tooltipIconMessage={orderSummaryIntl.formatMessage({ id: 'order-summary:environment-fee' })}
                        itemValue={envFees}
                        itemValueDataTestId={checkoutOrderSummaryDataLocators.checkoutOrderSummary_srp_dynValue}
                        dispatch={dispatch}
                    />
                    <OrderSummaryLineItem
                        itemLabel={orderSummaryIntl.formatMessage({ id: 'order-summary:other-fee' })}
                        itemLabelDataTestId={checkoutOrderSummaryDataLocators.checkoutOrderSummary_srp_lbl}
                        isTooltipIcon={false}
                        itemValue={otherFees}
                        itemValueDataTestId={checkoutOrderSummaryDataLocators.checkoutOrderSummary_srp_dynValue}
                        dispatch={dispatch}
                    />
                </div>
                <div className="ordersummary-checkout">
                    <OrderSummaryLineItem
                        itemLabel={orderSummaryIntl.formatMessage({ id: 'order-summary:subtotal' })}
                        itemLabelDataTestId={checkoutOrderSummaryDataLocators.checkoutOrderSummary_subtotal_lbl}
                        isTooltipIcon={false}
                        itemValue={subtotalValue}
                        itemValueDataTestId={checkoutOrderSummaryDataLocators.checkoutOrderSummary_subtotal_dynValue}
                        dispatch={dispatch}
                    />
                    <OrderSummaryLineItem
                        itemLabel={orderSummaryIntl.formatMessage({ id: 'order-summary:taxes' })}
                        itemLabelDataTestId={checkoutOrderSummaryDataLocators.checkoutOrderSummary_taxes_lbl}
                        isTooltipIcon={false}
                        itemValue={taxes}
                        itemValueDataTestId={checkoutOrderSummaryDataLocators.checkoutOrderSummary_taxesBlank_value}
                        dispatch={dispatch}
                    />
                </div>
                <div className="ordersummary-total">
                    <OrderSummaryLineItem
                        isBoldLabel
                        itemLabel={orderSummaryIntl.formatMessage({ id: itemLabel })}
                        itemLabelDataTestId={checkoutOrderSummaryDataLocators.checkoutOrderSummary_estimated_total_lbl}
                        isTooltipIcon={false}
                        itemValue={estimatedSubtotal}
                        itemValueDataTestId={
                            checkoutOrderSummaryDataLocators.checkoutOrderSummary_estimated_total_dynValue
                        }
                        dispatch={dispatch}
                    />
                </div>
                {shouldShowTbdAlert() && (
                    <Alert
                        type={'warning'}
                        icon={'warning'}
                        message={orderSummaryIntl.formatMessage({ id: 'order-summary:checkout-tbd-alert' })}
                    />
                )}
            </div>
        </>
    );
};

export default React.memo(CheckoutSummaryView);

CheckoutSummaryView.propTypes = {
    orderTotals: object,
    currentStep: number,
    formStep: object,
    source: string,
    deliveryChargeFlag: string,
    handleRoundTripAndEstimateFlow: func,
    isRatesFromTier2Radius: bool,
    isDeliveryFeeRequired: func
};
CheckoutSummaryView.defaultProps = {
    orderTotals: {},
    currentStep: 1,
    formStep: {},
    source: '',
    deliveryChargeFlag: '',
    handleRoundTripAndEstimateFlow: () => {},
    isRatesFromTier2Radius: false,
    isDeliveryFeeRequired: () => {}
};
