import React from 'react';
import { useCheckAuthorityType } from '../../hooks/useCheckUser';
import useMedia from '../../hooks/useMedia';
import SubmitReservationButton from '../global/atoms/SubmitReservation';
import CreateJobsite from './createJobsite/CreateJobsite';
import AccessoriesAndAddons from './accessoriesAndAddons';
import CustomAccountDetails from './checkoutAndOrderSummary/checkoutCustomAccountDetails/CheckoutCustomAccountDetails';
import CheckoutProjectInfo from './checkoutProjectInfo';
import HowToGetOrder from './howToGetYourOrder';
import OptionalPlans from './optionalPlans';
import TransmitOrSave from '../checkoutv2/transmitOrSave/TransmitOrSave';
import { isValidString } from '../global/utils/logger';
import { STORAGE_CONFIG } from '../../constants/storageConfig';
import { AUTHORITY_TYPE } from '../global/constants';

const CheckoutCreditUser = ({
    currentStep,
    setCurrentStep,
    currentOffSet,
    isCustomAccount,
    customBillingFields,
    formStep,
    isAccessoriesAddonsAvailable,
    multipleErrors,
    setMultipleErrors
}) => {
    const mediaType = useMedia();
    const authorityType = useCheckAuthorityType();
    const isSubmitReservationEnabled = currentStep === formStep.SUBMIT_RESERVATION ? true : false;
    const isCreateJobsite = isValidString(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREATEJOBSITE));
    const isTransmitOrSave = currentStep === formStep.TRANSMIT_OR_SAVE;

    const enableCreateProject = () => {
        return authorityType !== AUTHORITY_TYPE.P2P;
    };

    const showCustomAccountDetails = () => {
        if (!isCustomAccount) {
            return false;
        } else if (currentStep > formStep.PROJECT_DETAILS) {
            return true;
        } else if (isCreateJobsite && currentStep >= 3) {
            return true;
        } else {
            return false;
        }
    };    

    return (
        <>
            {isCreateJobsite && (
                <CreateJobsite
                    currentStep={currentStep}
                    handleStepChange={setCurrentStep}
                    currentOffSet={currentOffSet}
                    formStep={formStep}
                    multipleErrors={multipleErrors}
                    setMultipleErrors={setMultipleErrors}
                />
            )}
            <HowToGetOrder
                currentStep={currentStep}
                handleStepChange={setCurrentStep}
                currentOffSet={currentOffSet}
                formStep={formStep}
                isCustomAccount={isCustomAccount}
            />
            {isAccessoriesAddonsAvailable() && currentStep > formStep.HTGO && (
                <AccessoriesAndAddons
                    currentStep={currentStep}
                    handleStepChange={setCurrentStep}
                    currentOffSet={currentOffSet}
                    formStep={formStep}
                    isCustomAccount={isCustomAccount}
                    isAccessoriesAddonsAvailable={isAccessoriesAddonsAvailable}
                />
            )}
            <OptionalPlans
                currentStep={currentStep}
                handleStepChange={setCurrentStep}
                formStep={formStep}
                isCustomAccount={isCustomAccount}
            />
            {enableCreateProject() && (
                <CheckoutProjectInfo
                    currentStep={currentStep}
                    handleStepChange={setCurrentStep}
                    formStep={formStep}
                    isCustomAccount={isCustomAccount}
                />
            )}
            {showCustomAccountDetails() && (
                <CustomAccountDetails
                    currentStep={currentStep}
                    handleStepChange={setCurrentStep}
                    formStep={formStep}
                    customBillingFields={customBillingFields}
                />
            )}
            {mediaType === 'DESKTOP' && isSubmitReservationEnabled &&(
                <SubmitReservationButton
                    customClass={'submitCTAdesktopView'}
                    currentOffSet={currentOffSet}
                    currentStep={currentStep}
                    handleStepChange={setCurrentStep}
                />
            )}
            {isTransmitOrSave && authorityType === AUTHORITY_TYPE.P2P && <TransmitOrSave />}
        </>
    );
};

export default CheckoutCreditUser;
