import React, { memo, useState } from 'react';
import { bool, func, number } from 'prop-types';
import { useIntl } from 'react-intl';
import Input from '../input';
import Button from '../button/button';
import classes from './counterButton.css';
import Plus from '../../../../resources/images/plus.svg';
import Minus from '../../../../resources/images/minus.svg';
import { productTileDataLocator } from '../../modules/addToCartTile/dataLocators';
import { DECREMENT, INCREMENT } from '../../constants';
function CounterButton(props) {
    const { value, maxLimit, readOnly, handleValueChange, handleInputChange, maxQuantityLimit, setIsMaxQuantityReached, showAddToOrderButton, handleChangeOnInputBlur, shouldSendEnteredMaxValue} = props;

    const [isFocussed, setIsFocussed] = useState(false)
    const intl = useIntl();
    const maxQuantityHandler = maxLimit => {
        if (maxLimit <= maxQuantityLimit) {
            return maxLimit;
        } else return maxQuantityLimit;
    };
    const handleOnChange = e => {
        if (!e.target.value) {
            handleInputChange(-value);
            setIsMaxQuantityReached(false);
        }
        else if ((e.target.value <= maxQuantityHandler(maxLimit) && e.target.value >= 0)) {
            handleInputChange(parseInt(e.target.value));
            setIsMaxQuantityReached(false);
        } else if (shouldSendEnteredMaxValue && e.target.value >= maxQuantityHandler(maxLimit)) {
             handleInputChange(parseInt(e.target.value));
             setIsMaxQuantityReached(true);
        }
    };

    const handleValueInc = () => {
        if (value + 1 <= maxQuantityHandler(maxLimit) && value + 1 >= 0) {
            handleValueChange(value + 1, INCREMENT);
        } else setIsMaxQuantityReached(true);
    };
    const handleValueDec = () => {
        if (value - 1 >= 0) {
            handleValueChange(value - 1, DECREMENT);
            setIsMaxQuantityReached(false);
        }
    };
    const toggleFocus = (e) => {
        setIsFocussed(!isFocussed);
        if (handleChangeOnInputBlur) {
            handleChangeOnInputBlur(e?.target?.value);
        }
    };
    const handleKeyDown = (e) => {
        if (e.keyCode === 38) {
            handleValueInc()
            e.stopPropagation()
            e.preventDefault()
        } else if (e.keyCode === 40) {
            handleValueDec()
            e.stopPropagation()
            e.preventDefault()
        }
    }
    return (
        <>
            {value === 0 && !isFocussed && showAddToOrderButton ? (
                <div className={classes.counterContainer}>
                    <Button
                        className={`button button-outline-primary ${classes.counterButtonClass}`}
                        type="button"
                        buttonAriaLabel={`${intl.formatMessage({ id: 'cart-add-to-order' })}`}
                        onClick={handleValueInc}
                    // data-testid={checkoutDatalocator.guest_back_to_homepage_modal_cancel_testid}
                    >
                        <span className={classes.buttonTitle}>{intl.formatMessage({ id: 'cart-add-to-order' })}</span>
                    </Button>
                </div>
            ) : (
                <div className={classes.counterContainer}>
                    <Button
                        className={`button button-outline-primary ${classes.counterButtonClass}`}
                        type="button"
                        onClick={handleValueDec}
                        dataTestid={productTileDataLocator.cart_quantity_counter_button_minus}    
                        buttonAriaLabel={`${intl.formatMessage({ id: 'counter:decrement' })}`}>
                        <span className={classes.signContainer}>
                            <Minus />
                        </span>
                    </Button>
                    <div className={classes.counterInputContainer}>
                        <Input
                            handleInputFocus={toggleFocus}
                            handleInputChange={handleOnChange}
                            handleInputBlur={toggleFocus}
                            handleKeyDown={handleKeyDown}
                            inputAriaLabel={`${intl.formatMessage({
                                id: 'account:ac-common-enter-msg'
                            })} ${intl.formatMessage({ id: 'checkout-addOns:product-card-qty' })} `}
                            type="number"
                            data-testid={productTileDataLocator.cart_quantity_input_box}
                            value={value}
                            classes={classes.counterInputClass}
                            readOnly={readOnly}
                        />
                    </div>
                    <Button
                        className={`button button-outline-primary ${classes.counterButtonClass}`}
                        type="button"
                        onClick={handleValueInc}
                        dataTestid={productTileDataLocator.cart_quantity_counter_button_plus}     
                        buttonAriaLabel={`${intl.formatMessage({ id: 'counter:increment' })}`}>
                        <span className={classes.signContainer}>
                            <Plus />
                        </span>
                    </Button>
                </div>
            )}
        </>
    );
}

CounterButton.propsType = {
    value: number,
    maxLimit: number,
    handleValueChange: func,
    readOnly: bool,
    maxQuantityLimit: number,
    showAddToOrderButton: bool,
    shouldSendEnteredMaxValue: bool,
};

CounterButton.defaultProps = {
    value: 0,
    maxLimit: parseInt(document.querySelector('.checkout')?.dataset?.maxquantitylimit),
    handleValueChange: () => { },
    readOnly: false,
    maxQuantityLimit: parseInt(document.querySelector('.checkout')?.dataset?.maxquantitylimit),
    showAddToOrderButton: true,
    shouldSendEnteredMaxValue: false,
};

export default memo(CounterButton)