import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useCheckUser } from '../../../hooks/useCheckUser';
import { logError } from '../../global/utils/logger';
import useCheckout from '../../../hooks/useCheckout';
import { useAnalyticsContext } from '../../../config/GoogleTagManagerEvents';
import { useFilterState } from '../../cap';
import { ReadOnlyView } from '../../global/modules/stepForm';
import AlertModalWithButtons from '../../global/atoms/AlertModalWithButtons';
import GetDirectionIcon from '../../../resources/images/get-direction.svg';
import InstructionsIcon from '../../../resources/images/instructions.svg';
import PhoneIcon from '../../../resources/images/phone.svg';
import { checkoutDatalocator } from '../checkoutAndOrderSummary/dataLocators';
import { VARIABLE_CONFIG } from '../../../constants/analyticsConstants/Variables';

const ReadOnlyCreateJobsite = props => {
    const intl = useIntl();
    const { clearCheckout } = useCheckout();
    const [{ projectDetails }] = useFilterState();
    const [showAlertModal, setShowAlertModal] = useState(false);
    const { sendEventsForClick } = useAnalyticsContext();
    const { handleStepChange, formStep } = props;
    const userType = useCheckUser();
    const editHandler = () => {
        try {
            sendEventsForClick(
                VARIABLE_CONFIG.EVENT.UAEVENT,
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                VARIABLE_CONFIG.EVENT_CATEGORY.CHECKOUT_PAGE,
                `${userType.toLowerCase()} select`,
                `${intl.formatMessage({ id: 'checkout:how-to-get-your-order-title' }).toLowerCase()} edit`
            );
        } catch (error) {
            logError(error, false, 'editHandler');
        }
        setShowAlertModal(true);
    };

    const handleMakeChangesClick = () => {
        setShowAlertModal(false);
        handleStepChange(formStep.CREATE_JOBSITE);
        clearCheckout(formStep, formStep.CREATE_JOBSITE);
    };

    const handleCancelClick = () => {
        setShowAlertModal(false);
    };

    const renderReadOnlyBody = () => {
        return (
            <>
                <div className="order-details-item">
                    <GetDirectionIcon />
                    <div className="order-details-desc">
                        <div className="order-details-title">
                            {intl.formatMessage({ id: 'checkout:read-only-jobsite-details' })}
                        </div>
                        <span className="order-details-name">{projectDetails?.projectName}</span>
                        <span className="order-details-address">{projectDetails?.projectAddress1}</span>
                        <span className="order-details-address">
                            {projectDetails?.selectedProjectCity} {projectDetails?.selectedProjectState}{' '}
                            {projectDetails?.selectedProjectZip}{' '}
                        </span>
                    </div>
                </div>

                {(projectDetails?.accessNotes || projectDetails?.poNumber) && (
                    <div className="order-details-item">
                        <InstructionsIcon />
                        <div className="order-details-desc">
                            <div className="order-details-title">
                                {intl.formatMessage({ id: 'checkout:read-only-jobsite-instructions' })}
                            </div>
                            {projectDetails?.accessNotes && (
                                <span className="order-details-address">
                                    {projectDetails?.accessNotes}
                                </span>
                            )}
                            {projectDetails?.poNumber && (
                                <span className="order-details-address">
                                    {intl.formatMessage({ id: 'checkout:read-only-po-number' })}{' '}
                                    {projectDetails?.poNumber}
                                </span>
                            )}
                        </div>
                    </div>
                )}

                <div className="order-details-item">
                    <PhoneIcon />
                    <div className="order-details-desc">
                        <div className="order-details-title">
                            {intl.formatMessage({ id: 'checkout:read-only-contact-information' })}
                        </div>
                        <span className="order-details-address">{projectDetails?.primaryContactName}</span>
                        <span className="order-details-address">{projectDetails?.phoneNumber}</span>
                    </div>
                </div>
            </>
        );
    };

    return (
        <>
            <ReadOnlyView
                children={renderReadOnlyBody()}
                title={intl.formatMessage({ id: 'checkout:create-jobsite-title' })}
                testId={checkoutDatalocator.checkout_howtogetyourorder_link_testid}
                onEdit={editHandler}
            />
            <AlertModalWithButtons
                isOpen={showAlertModal}
                title={intl.formatMessage({ id: 'checkout:alert-heading' })}
                warningQuestion={intl.formatMessage({ id: 'checkout:alert-body-content' })}
                handleContinueClick={handleMakeChangesClick}
                continueButtonText={intl.formatMessage({ id: 'checkout:alert-body-button' })}
                handleCancelClick={handleCancelClick}
                cancelButtonText={intl.formatMessage({ id: 'account:address-delete-cancel' })}
                closeIconDataLocator={checkoutDatalocator.checkout_crossicon_testid}
                continueButtonDataLocator={checkoutDatalocator.checkout_makechanges_CTA_testid}
                cancelButtonDataLocator={checkoutDatalocator.checkout_cancel_CTA_testid}
                titleDataLocator={checkoutDatalocator.checkout_doyouwanttomakechanges_heading_testid}
                warningQuestionDataLocator={checkoutDatalocator.checkout_changesrequiredyourinformartion_labels_testid}
                alertTooltipDataLocator={checkoutDatalocator.checkout_makechangesmodal_tooltip_testid}
            />
        </>
    );
};

export default ReadOnlyCreateJobsite;
