import React from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';
import useRoundTrip from '../../../hooks/useRoundTrip';
import { useCheckUser } from '../../../hooks/useCheckUser';
import { useCapUtils } from '../../cap/hooks/useCapUtils';
import useUserData from '../../../hooks/useUserData';
import useFulfillmentHooks from '../../checkoutv2/utils/useFulFillmentHooks';
import useCheckLocationEmpty from '../../../hooks/useCheckLocationEmpty';
import isObjectWithKeys from '../../../aem-core-components/utils/isObjectWithKeys';
import timeSlots from '../../checkoutv2/utils/timeSlots';
import { getLeadTime, getTodayCondition, getTomorrowCondition } from '../../checkoutv2/utils/editModuleFunctions';
import {
    adjustDate,
    findNextAvailableSlot,
    getTimeStops,
    makeTimeInterval,
    tommorrowDate
} from '../../checkoutv2/utils/checkoutUtils';
import { convertDateToMomentDateTime } from '../../global/utils/commonUtils';
import { logError } from '../../global/utils/logger';
import { updateSelectedStoreDetailsForRoundTrip } from '../../global/utils/computeAddressUtil';
import { getStoresData } from '../../global/modules/Stores/Api/getStoresData';
import { getDateSlotAvailability } from '../../checkoutv2/checkoutAndOrderSummary/api/getCheckoutAPIs';
import { useFilterState } from '../../cap';
import { useCartState } from '../../../contexts/cart/cartContext';
import { STORAGE_CONFIG } from '../../../constants/storageConfig';
import { USER_TYPE } from '../../../constants/userDetailsConstants';
import { HOW_TO_GET_YOUR_ORDER } from '../../../constants/cartConstants';
import {
    SET_CHECKOUT_ERROR_DETAILS,
    SET_CURRENT_OFFSET_VALUE,
    SET_END_DATE_SLOTS,
    SET_HOW_TO_GET_YOUR_ORDER_FIELDS,
    SET_LOCATION_DATA,
    SET_PICKUP_TIME_SLOTS,
    SET_RETURN_TIME_SLOTS,
    SET_SEARCH_RADIUS_FOR_LOCATION_CALL,
    SET_START_DATE_SLOTS,
    SET_TIMEZONE_ID
} from '../../../aem-core-components/actions/constants';
import { ENV_CONFIG } from '../../../constants/envConfig';
import { EXPANDED_TIER_ONE_SEARCH_RADIUS, SET_SELECTED_STORE_DETAILS } from '../../cap/constants';

const useCheckoutNavigate = () => {
    const intl = useIntl();
    const [{ isCreditNewAddress, cart, currentOffset, timeZoneID }, cartDispatch] = useCartState();
    const [{ viewCart, projectDetails, startDate, endDate, searchRadiusForLocationCall }, filterDispatch] =
        useFilterState();
    const { fetchLocationCoordinates } = useCheckLocationEmpty();
    const { getAvsResponse, isCanadaStateValid, fetchTimeZoneAPI, computeAvsAddressLine1, isLargeEquipment } =
        useRoundTrip();
    const { fetchAndUpdatePickupStores } = useCapUtils();
    const userType = useCheckUser();
    const [{ updateGeography }] = useUserData();
    const { isIn24Hrs, isValidSlotInLast24Hours, validatedPickupSlot } = useFulfillmentHooks();
    const COMPANY_ID = parseInt(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID)) || 1;

    const CHECKOUT_VALIDATION_ERRORS = {
        AVS_INVALID_ADDRESS: intl.formatMessage({ id: 'checkout:invalid-address-error' }),
        TIMEZONE_ERROR: intl.formatMessage({ id: 'checkout:failed-timezone-error' }),
        STORES_UNAVAILABLE: intl.formatMessage({ id: 'checkout:no-available-stores' }),
        DELIVERY_SLOTS_UNAVAILABLE: intl.formatMessage({ id: 'checkout:no-delivery-slots-available' }),
        PICKUP_SLOTS_UNAVAILABLE: intl.formatMessage({ id: 'checkout:no-pickup-slots-available' }),
        TIME_SLOTS_UNAVAILABEL: intl.formatMessage({ id: 'checkout:no-time-slot-error' })
    };

    /**
     * This function takes three arguments - a boolean variable to fetch details from contex, a object to get details from avs and object to get details form stores data.
     * It returns a object with user detials.
     * @param {*} isFromContext
     * @param {*} addressFromAvs
     * @param {*} storesData
     * @returns
     */
    const getLocationDetails = (isFromContext = true, addressFromAvs = {}, storesData = {}, deliveryStoreIndex = 0) => {
        try {
            if (isFromContext) {
                if (isCreditNewAddress) {
                    return {
                        lat: viewCart?.lat,
                        long: viewCart?.long,
                        selectedCity: viewCart?.jobSiteCity,
                        streetAddress: viewCart?.location?.split(',')?.[0],
                        selectedZip: viewCart?.jobSiteZip,
                        selectedAddress2: viewCart?.jobSiteAddr2,
                        selectedState: viewCart?.jobSiteState,
                        startDate,
                        endDate
                    };
                } else {
                    return {
                        lat: projectDetails?.selectedProjectLatititude,
                        long: projectDetails?.selectedProjectLongitude,
                        selectedCity: projectDetails?.selectedProjectCity,
                        streetAddress: projectDetails?.projectAddress1,
                        selectedZip: projectDetails?.selectedProjectZip,
                        selectedAddress2: projectDetails?.projectAddress2,
                        selectedState: projectDetails?.selectedProjectState,
                        startDate,
                        endDate
                    };
                }
            } else {
                if (isObjectWithKeys(addressFromAvs)) {
                    return {
                        location: computeAvsAddressLine1(addressFromAvs) || addressFromAvs?.address1,
                        lat: addressFromAvs?.latFromAVS,
                        long: addressFromAvs?.longFromAVS,
                        jobSiteAddr2: addressFromAvs?.address2,
                        jobSiteCity: addressFromAvs?.city,
                        jobSiteState: addressFromAvs?.state,
                        jobSiteZip: addressFromAvs?.zip,
                        pc: storesData?.data?.[deliveryStoreIndex]?.pc,
                        pcLat: storesData?.data?.[deliveryStoreIndex]?.latitude,
                        pcLong: storesData?.data?.[deliveryStoreIndex]?.longitude,
                        distance: storesData?.data?.[deliveryStoreIndex]?.drivingDistanceFromJobsite || '',
                        specialtyTypes: storesData?.data?.[deliveryStoreIndex]?.specialtyTypes
                    };
                } else {
                    let localLocation = getLocationDetails();
                    return {
                        location: localLocation?.streetAddress,
                        lat: parseFloat(localLocation?.lat),
                        long: parseFloat(localLocation?.long),
                        jobSiteAddr2: localLocation?.selectedAddress2,
                        jobSiteCity: localLocation?.selectedCity,
                        jobSiteState: localLocation?.selectedState,
                        jobSiteZip: localLocation?.selectedZip,
                        pc: storesData?.data?.[deliveryStoreIndex]?.pc,
                        pcLat: storesData?.data?.[deliveryStoreIndex]?.latitude,
                        pcLong: storesData?.data?.[deliveryStoreIndex]?.longitude,
                        distance: storesData?.data?.[deliveryStoreIndex]?.drivingDistanceFromJobsite,
                        specialtyTypes: storesData?.data?.[deliveryStoreIndex]?.specialtyTypes
                    };
                }
            }
        } catch (error) {
            logError(error, false, 'getLocationDetails', [isFromContext, addressFromAvs, storesData]);
            return {};
        }
    };

    const getAddressForAvs = () => {
        const addressObj = JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.VIEWCART) || '{}');
        return {
            lat: addressObj?.lat,
            long: addressObj?.long,
            selectedCity: addressObj?.jobSiteCity,
            streetAddress: addressObj?.location?.split(',')?.[0],
            selectedZip: addressObj?.jobSiteZip,
            selectedAddress2: addressObj?.jobSiteAddr2,
            selectedState: addressObj?.jobSiteState,
            startDate,
            endDate
        };
    };

    /**
     * This function calls the AVS verify API.
     * It returns a object with avs address details, lat, long and error.
     * @returns
     */
    const validateAvsCheck = async () => {
        try {
            // check if avs response is already present
            let avsResponse = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.AVS_RESPONSE) || '{}');
            if (isObjectWithKeys(avsResponse)) {
                return avsResponse;
            }
            /** Fetching address from local storage instead of context,
             * as when user changes address based on avs suggestion
             * it will take time to reflect in context so, here we will not
             * get the latest data
             */
            const localLocation = getAddressForAvs();
            let isStateValid = isCanadaStateValid(localLocation?.selectedState);
            if (!isStateValid) {
                return { addressFromAvs: '', avsError: CHECKOUT_VALIDATION_ERRORS.AVS_INVALID_ADDRESS };
            }
            /* Calls the verify AVS check for the address selected by the user */
            avsResponse = await getAvsResponse(localLocation, true);
            if (avsResponse?.isValid && isAddressDifferentFromAvs(localLocation, avsResponse?.addressFromAvs)) {
                return {
                    ...avsResponse,
                    isValid: false,
                    isAddressDifferent: true
                };
            } else {
                return avsResponse;
            }
        } catch (error) {
            logError(error, false, 'validateAvsCheck');
            return { error };
        }
    };

    /**
     * This function takes one argument - a object with the adress details forn verify API.
     * It returns a object with timeZone and offset value.
     * @param {*} addressFromAvs
     * @returns
     */
    const validateTimezoneAPI = async (addressFromAvs = {}) => {
        try {
            let address;
            if (isObjectWithKeys(addressFromAvs)) {
                address = `${addressFromAvs?.address1}, ${addressFromAvs?.city}, ${addressFromAvs?.state}`;
            } else {
                const localLocation = getLocationDetails();
                address = localLocation
                    ? `${localLocation?.streetAddress}, ${localLocation?.selectedCity}, ${localLocation?.selectedState}`
                    : '';
            }
            /* Fetch the timeZoneID and offset for the address entered by the user */
            const { offset, timeZoneID, error } = await fetchTimeZoneAPI(address);
            if (error) {
                // resetting if data is not present
                dispatch({ type: SET_TIMEZONE_ID, timeZoneID: timeZoneID || '' });
                dispatch({ type: SET_CURRENT_OFFSET_VALUE, currentOffset: offset || '' });
            }
            return { timeZoneID, offset, timeZoneError: error };
        } catch (error) {
            logError(error, false, 'validateTimezoneAPI', [addressFromAvs]);
            return {
                timeZoneID: '',
                offset: '',
                timeZoneError: CHECKOUT_VALIDATION_ERRORS.TIMEZONE_ERROR
            };
        }
    };

    /**
     * This function takes one argument - a object with the overridePC details.
     * It returns a object with formatted data.
     * @param {*} overridePC
     * @returns
     */
    const getOverridePcData = overridePC => {
        const data = {
            data: [
                {
                    pc: overridePC?.pc,
                    latitude: overridePC?.latitude,
                    longitude: overridePC?.longitude,
                    drivingDistanceFromJobsite: overridePC?.distance,
                    specialtyTypes: overridePC?.specialtyTypes
                }
            ]
        };
        return { data, status: 200 };
    };
    /**
     * This function takes one argument - an array with the product details.
     * It returns a object with formatted data.
     * @param {*} cartItems
     * @returns
     */

    const getAssets = cartItems => {
        const assets = [];
        cartItems?.forEach((item, index) => {
            const { sku = '' } = item?.product || {};
            const quantity = item?.quantity;
            assets.push({
                productId: sku,
                quantity,
                sequenceNumber: index
            });
        });
        return assets;
    };

    /**
     * This function takes two arguments - adrress forn AVS if it is there, available items in the cart.
     * It fetches the chronos store based on avaialble items and store selected by the user
     * It returns a object with formatted data.
     * @param {*} addressFromAvs
     * @param {*} availableCartItems
     * @returns selected store data form chronos, error, storeIndex which default to 0
     */
    const getChronosStores = async ({
        addressFromAvs = {},
        availableCartItems = cart?.availableCartItems,
        isDelivery = !viewCart?.isInStorePickup
    }) => {
        try {
            const { localLat, localLong } = fetchLocationCoordinates();
            const getStoresPayload = {
                latitude: Number(addressFromAvs?.latFromAVS || localLat),
                longitude: Number(addressFromAvs?.longFromAVS || localLong),
                pickupDateTime: convertDateToMomentDateTime(startDate, ''),
                returnDateTime: convertDateToMomentDateTime(endDate, ''),
                assets: getAssets(availableCartItems),
                isDelivery,
                isExpressDelivery: false
            };
            const overridePC = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC) || '{}');
            const {
                data,
                error,
                deliveryStoreIndex = 0
            } = overridePC?.pc ? getOverridePcData(overridePC) : await getStoresData({ ...getStoresPayload });
            return { data, error, deliveryStoreIndex };
        } catch (err) {
            logError(err, false, 'getChronosStores', [addressFromAvs]);
            return { data: [], error: CHECKOUT_VALIDATION_ERRORS.STORES_UNAVAILABLE, deliveryStoreIndex };
        }
    };

    /**
     * This function updates the contex if there is any error to the use while checkout form the cart
     * @param {*} storesError
     * @param {*} timeZoneError
     * @param {*} startDateError
     * @param {*} endDateError
     * @param {*} avsError
     * @param {*} timeSlotsError
     */
    const dispatchCheckoutErrDetails = ({
        storesError = '',
        timeZoneError = '',
        startDateError = '',
        endDateError = '',
        avsError = '',
        timeSlotsError = ''
    }) => {
        cartDispatch({
            type: SET_CHECKOUT_ERROR_DETAILS,
            storesError,
            timeZoneError,
            startDateError,
            endDateError,
            avsError,
            timeSlotsError
        });
        cartDispatch({ type: 'endLoading' });
    };

    /**
     * This function takes two argument - a string with offset value and an object with the address details.
     * It returns a object with stores data, status and error.
     * @param {*} offset
     * @param {*} addressFromAvs
     * @returns
     */
    const validateStoresData = async (addressFromAvs = {}, chronosStores, availableCartItems, isDelivery) => {
        try {
            let data = {
                data: chronosStores
            };
            let error = '';
            let deliveryStoreIndex = 0;
            // not checking for length as sources data might be empty array
            if (!chronosStores) {
                ({
                    data,
                    error,
                    deliveryStoreIndex = 0
                } = await getChronosStores({ addressFromAvs, availableCartItems, isDelivery }));
            }
            if (error || !data?.data?.length) {
                return { data: {}, storesError: error?.message || CHECKOUT_VALIDATION_ERRORS.STORES_UNAVAILABLE };
            }
            updateSourceAndLocationSearchRadius(data?.data?.[deliveryStoreIndex]?.distanceFromJobSite);
            if (!isDelivery) {
                /** sources call is made for pickup on page load, so the user has already selected a store from sources through discrepency drawer
                 *  if still the selected store is not present in sources that means on calling sources again with new available items, the store
                 *  is not there, which is a very rare edge case. so passing 1 as fulfillment with the selected store.
                 *  */
                const selectedStore = JSON.parse(
                    localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.SELECTEDSTOREDETAILS) || '{}'
                );
                if (selectedStore && selectedStore?.pc !== data?.data?.[deliveryStoreIndex]?.pc) {
                    // if selected store in not present in chronos, then passing the selected store with fulfillment percentage as 1
                    selectedStore.fulfillmentPercent = 1;
                    data = {
                        data: [selectedStore]
                    };
                    deliveryStoreIndex = 0;
                }
            } else {
                // for delivery, considering the first source pc instead of selected store pc
                deliveryStoreIndex = 0;
            }
            const { localLat, localLong } = fetchLocationCoordinates();
            const payload = {
                pc: data?.data?.[deliveryStoreIndex]?.pc,
                lat: data?.data?.[deliveryStoreIndex]?.latitude,
                long: data?.data?.[deliveryStoreIndex]?.longitude,
                localLat: Number(addressFromAvs?.latFromAVS || localLat),
                localLong: Number(addressFromAvs?.longFromAVS || localLong),
                distance: data?.data?.[deliveryStoreIndex]?.drivingDistanceFromJobsite,
                specialtyTypes: data?.data?.[deliveryStoreIndex]?.specialtyTypes
            };
            cartDispatch({
                type: SET_HOW_TO_GET_YOUR_ORDER_FIELDS,
                key: HOW_TO_GET_YOUR_ORDER.BEST_MATCH_STORES_DATA,
                value: data
            });
            if (data?.data?.length > 0) {
                updateSelectedStoreDetailsForRoundTrip(data?.data?.[deliveryStoreIndex]);
            }
            if (data?.data?.[deliveryStoreIndex]?.pc) {
                cartDispatch({
                    type: SET_HOW_TO_GET_YOUR_ORDER_FIELDS,
                    key: HOW_TO_GET_YOUR_ORDER.SELECTED_STORE_DETAILS,
                    value: data?.data?.[deliveryStoreIndex]
                });
                filterDispatch({
                    type: SET_SELECTED_STORE_DETAILS,
                    selectedStoreDetails: data?.data?.[deliveryStoreIndex]
                });
            }
            updateGeography(
                data?.data?.[deliveryStoreIndex]?.pc,
                data?.data?.[deliveryStoreIndex]?.latitude,
                data?.data?.[deliveryStoreIndex]?.longitude,
                data?.data?.[deliveryStoreIndex]?.drivingDistanceFromJobsite,
                data?.data?.[deliveryStoreIndex]?.specialtyTypes,
                Number(addressFromAvs?.latFromAVS || localLat),
                Number(addressFromAvs?.longFromAVS || localLong)
            );
            if (isObjectWithKeys(addressFromAvs)) {
                const locationObj = getLocationDetails(false, addressFromAvs, data, deliveryStoreIndex);
                filterDispatch({
                    type: SET_LOCATION_DATA,
                    pc: locationObj?.pc,
                    location: locationObj?.location,
                    jobSiteCity: locationObj?.jobSiteCity,
                    jobSiteState: locationObj?.jobSiteState,
                    jobSiteZip: locationObj?.jobSiteZip,
                    lat: locationObj?.lat,
                    long: locationObj?.long,
                    jobSiteAddr2: locationObj?.jobSiteAddr2,
                    pcLat: locationObj?.pcLat,
                    pcLong: locationObj?.pcLong
                });
            }
            return { data, storePcObj: payload, storesError: error?.message, deliveryStoreIndex };
        } catch (err) {
            logError(err, false, 'validateStoresData', [addressFromAvs]);
            return { data: [], storesError: CHECKOUT_VALIDATION_ERRORS.STORES_UNAVAILABLE };
        }
    };

    const updateSourceAndLocationSearchRadius = (storeDistance = 0) => {
        const { tieroneinvradius } = ENV_CONFIG.INVENTORY_CHECK_CONFIGS || {};
        const tier1RadiusFromConfig = tieroneinvradius || 100;
        const isTier1Radius = storeDistance <= tier1RadiusFromConfig;
        const { tier1SearchRadius } = searchRadiusForLocationCall || {};
        sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.IS_SOURCES_TIER_2_RADIUS, !isTier1Radius); //if store within 100 miles
        if (!isTier1Radius && tier1SearchRadius !== EXPANDED_TIER_ONE_SEARCH_RADIUS) {
            filterDispatch({
                type: SET_SEARCH_RADIUS_FOR_LOCATION_CALL,
                key: 'tier1SearchRadius',
                value: EXPANDED_TIER_ONE_SEARCH_RADIUS
            });
            fetchAndUpdatePickupStores(EXPANDED_TIER_ONE_SEARCH_RADIUS);
        }
    };

    /**
     * This function takes three arguments - an object with the address details, an array with stores data and a string with offset value.
     * It returns a object with start and end date slots.
     * @param {*} addressFromAvs
     * @param {*} storesData
     * @param {*} offset
     * @returns
     */
    const fetchDateSlotsFromWindowsApi = async (
        addressFromAvs = {},
        storesData,
        offset,
        deliveryStoreIndex = 0,
        availableCartItems,
        startDate,
        isInitialCall,
        isDelivery
    ) => {
        try {
            let localLocationObj = getLocationDetails(false, addressFromAvs, storesData, deliveryStoreIndex);
            let startDateSlots = {};
            let endDateSlots = {};
            if (isInitialCall) {
                [startDateSlots, endDateSlots] = await Promise.all([
                    getDateSlotAvailability(
                        COMPANY_ID,
                        localLocationObj?.lat,
                        localLocationObj?.long,
                        startDate,
                        getAssets(availableCartItems),
                        isDelivery,
                        localLocationObj?.jobSiteZip,
                        isLargeEquipment(),
                        offset,
                        localLocationObj?.pc,
                        false
                    ),
                    getDateSlotAvailability(
                        COMPANY_ID,
                        localLocationObj?.lat,
                        localLocationObj?.long,
                        endDate,
                        getAssets(availableCartItems),
                        isDelivery,
                        localLocationObj?.jobSiteZip,
                        isLargeEquipment(),
                        offset,
                        localLocationObj?.pc,
                        false
                    )
                ]);
                cartDispatch({ type: SET_START_DATE_SLOTS, windowsResponse: startDateSlots });
                cartDispatch({ type: SET_END_DATE_SLOTS, windowsResponse: endDateSlots });
            } else {
                startDateSlots = await getDateSlotAvailability(
                    COMPANY_ID,
                    localLocationObj?.lat,
                    localLocationObj?.long,
                    startDate,
                    getAssets(availableCartItems),
                    isDelivery,
                    localLocationObj?.jobSiteZip,
                    isLargeEquipment(),
                    offset,
                    localLocationObj?.pc,
                    false
                );
                cartDispatch({ type: SET_START_DATE_SLOTS, windowsResponse: startDateSlots });
            }

            let startDateWindows = startDateSlots?.data?.data?.windows;
            let filteredStartDateSlots = startDateWindows?.filter(
                window => moment(window?.start).format('MMM DD, YYYY') == moment(startDate).format('MMM DD, YYYY')
            );

            let isValidSlot = filteredStartDateSlots?.length;
            //here will check if slot is in 24 hours then slot time should be after currentOffset
            if (isIn24Hrs(startDate, currentOffset) && filteredStartDateSlots?.length) {
                const lastStartDateSlot = filteredStartDateSlots[filteredStartDateSlots?.length - 1]?.start;
                isValidSlot = isValidSlotInLast24Hours(lastStartDateSlot, currentOffset);
            }

            // If no delivery slots are available for the selected startDate
            if (!isValidSlot) {
                // Find the next available slot after the end of the selected date
                const nextStartAvailableSlot = findNextAvailableSlot(startDateWindows, startDate);
                // If next startDate slot is available then return nextAvailableSlot.
                if (nextStartAvailableSlot) {
                    return {
                        startDateSlots,
                        endDateSlots,
                        startDateError: CHECKOUT_VALIDATION_ERRORS.DELIVERY_SLOTS_UNAVAILABLE,
                        endDateError: '',
                        nextAvailableSlot: nextStartAvailableSlot
                    };
                }
            }
            if (!isValidSlot || startDateSlots?.error) {
                return {
                    startDateSlots,
                    endDateSlots,
                    startDateError: CHECKOUT_VALIDATION_ERRORS.DELIVERY_SLOTS_UNAVAILABLE,
                    endDateError: ''
                };
            }

            if (isInitialCall) {
                let endDateWindows = endDateSlots?.data?.data?.windows;
                let filteredEndDateSlots = endDateWindows?.filter(
                    window => moment(window?.start).format('MMM DD, YYYY') == moment(endDate).format('MMM DD, YYYY')
                );
                if (!filteredEndDateSlots?.length || endDateSlots?.error) {
                    return {
                        startDateSlots,
                        endDateSlots,
                        startDateError: '',
                        endDateError: CHECKOUT_VALIDATION_ERRORS.PICKUP_SLOTS_UNAVAILABLE
                    };
                }
            }

            return { startDateSlots, endDateSlots, startDateError: '', endDateError: '', nextAvailableSlot: '' };
        } catch (error) {
            logError(error, false, 'fetchDateSlotsFromWindowsApi', [addressFromAvs, storesData, offset]);
            return {
                startDateSlots: {},
                endDateSlots: {},
                startDateError: CHECKOUT_VALIDATION_ERRORS.DELIVERY_SLOTS_UNAVAILABLE,
                endDateError: CHECKOUT_VALIDATION_ERRORS.PICKUP_SLOTS_UNAVAILABLE
            };
        }
    };

    const isAddressDifferentFromAvs = (localLocation, addressFromAvs) => {
        return (
            addressFromAvs?.address1 !== localLocation?.streetAddress ||
            addressFromAvs?.city !== localLocation?.selectedCity ||
            addressFromAvs?.state !== localLocation?.selectedState ||
            addressFromAvs?.zip !== localLocation?.selectedZip
        );
    };

    /**
     * It returns a object with isValid boolean vriable.
     * @returns
     */
    const computeChronosStoreWithAVS = async (chronosStores, availableCartItems, isDelivery) => {
        try {
            let avsResponse = {};
            if (isDelivery && isCreditNewAddress && userType !== USER_TYPE.GUEST) {
                avsResponse = await validateAvsCheck();
                if (!avsResponse?.error) {
                    /** saving avs error response to avoid call again
                     *  not saving if there is any error from api
                     */
                    sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.AVS_RESPONSE, JSON.stringify(avsResponse));
                }
                if (!avsResponse?.isValid) {
                    return { isErrorFromAvs: true, avsAddressDetails: avsResponse };
                }
            }

            const { addressFromAvs = {}, latFromAVS, longFromAVS } = avsResponse;
            const avsAddressDetails = isObjectWithKeys(addressFromAvs)
                ? { ...addressFromAvs, latFromAVS, longFromAVS }
                : {};

            const storesData = await validateStoresData(
                avsAddressDetails,
                chronosStores,
                availableCartItems,
                isDelivery
            );
            if (storesData?.storesError) {
                dispatchCheckoutErrDetails({ storesError: storesData?.storesError });
            }
            return {
                storesData,
                avsAddressDetails
            };
        } catch (error) {
            logError(error, false, 'computeChronosStoreWithAVS');
            return {};
        }
    };

    const validateDateSlots = async ({
        isCheckout = false,
        storesData = {},
        avsAddressDetails = {},
        availableCartItems = cart?.availableCartItems,
        isDelivery = !viewCart?.isInStorePickup
    }) => {
        try {
            const overridePC = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC) || '{}');
            let pickupTimeSlots = [],
                returnTimeSlots = [],
                startDateSlots = {},
                endDateSlots = {},
                startDateError = '',
                endDateError = '',
                nextAvailableSlot = '';

            /* Fetch the dateslots for pickupstore to place the order */
            const fetchDateSlots = async (isInitialCall, attempt = 1, startDate) => {
                const maxAttempts = 3; // Total windows api attempts to prevent infinite recursion

                ({
                    startDateSlots = {},
                    endDateSlots = {},
                    startDateError = '',
                    endDateError = '',
                    nextAvailableSlot = ''
                } = await fetchDateSlotsFromWindowsApi(
                    avsAddressDetails,
                    storesData?.data,
                    currentOffset,
                    storesData?.deliveryStoreIndex,
                    availableCartItems,
                    startDate,
                    isInitialCall,
                    isDelivery
                ));

                if (startDateError !== '' && attempt < maxAttempts && !nextAvailableSlot) {
                    // if startDateError has error and nextAvailableSlot is empty then we will retrying the api
                    let availableStartDate = moment(startDate).add(4, 'days');
                    // Adjust start date to ensure they are not on weekends and holidays
                    availableStartDate = adjustDate(availableStartDate);

                    return fetchDateSlots(false, attempt + 1, availableStartDate?.toDate()); // Recursive call
                }

                return { startDateSlots, endDateSlots, startDateError, endDateError, nextAvailableSlot };
            };

            if (!isDelivery) {
                ({ pickupTimeSlots, returnTimeSlots } = getPickupSlotsFromSelectedStore(
                    storesData?.data,
                    currentOffset
                ));

                let pickupSlotUnavailableDate = null;
                if (!pickupTimeSlots?.length) {
                    pickupSlotUnavailableDate = startDate;
                } else if (!returnTimeSlots?.length) {
                    pickupSlotUnavailableDate = endDate;
                }

                if (!pickupTimeSlots?.length || !returnTimeSlots?.length) {
                    return { isValid: false, pickupSlotUnavailableDate };
                }
                // will check last pickup slot within 24 hours is valid or not
                if (isIn24Hrs(startDate, currentOffset) && pickupTimeSlots?.length) {
                    pickupSlotUnavailableDate = startDate;
                    const lastPickupTimeSlot = pickupTimeSlots[pickupTimeSlots?.length - 1];
                    const isValidSlot = validatedPickupSlot(startDate, lastPickupTimeSlot, currentOffset, timeZoneID);
                    if (!isValidSlot) {
                        return { isValid: false, pickupSlotUnavailableDate };
                    }
                }
            } else {
                /* Fetch the dateslots from windows for delivery to place
                the order in CASH and CREDIT as in GUEST flow user won't have option for RTD */
                const slotsResponse = await fetchDateSlots(true, 1, startDate);
                ({ startDateSlots, endDateSlots, startDateError, endDateError, nextAvailableSlot } = slotsResponse);
                if (startDateError || endDateError) {
                    return { isValid: false, nextAvailableSlot, endDateError };
                }
            }

            if (!isCheckout) {
                const sessionData = {
                    sourcePCbj: storesData?.storePcObj,
                    timeZoneResponse: { timeZoneID, offset: currentOffset },
                    selectedStoreData: overridePC?.pc
                        ? overridePC
                        : storesData?.data?.data?.[storesData?.deliveryStoreIndex] || {},
                    startDateSlots,
                    endDateSlots,
                    pickupTimeSlots,
                    returnTimeSlots
                };
                sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.CHECKOUTUSERDETAILS, JSON.stringify(sessionData));
            } else {
                sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.CHECKOUTUSERDETAILS);
            }
            return { isValid: true };
        } catch (error) {
            logError(error, false, 'validateDateSlots');
            return { isValid: false };
        }
    };

    /**
     * This function fetch the timeslots based on store selected by the user and is offset for the timezone API
     * @param {*} storesData
     * @param {*} currentOffSet
     * @returns the pickupTimeSlots and returnTimeSlots
     */

    const getPickupSlotsFromSelectedStore = (storesData, currentOffSet) => {
        try {
            const pickupDateWithTime = startDate ? new Date(startDate) : new Date();
            const returnDateWithTime = endDate ? new Date(endDate) : new Date();
            const returnDay = returnDateWithTime.getDay();
            const pickupDay = pickupDateWithTime.getDay();
            const selectedStore = JSON.parse(
                localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.SELECTEDSTOREDETAILS) || '{}'
            );
            const {
                storeOpeningTimeForPickup,
                storeClosingTimeForPickup,
                storeOpeningTimeForReturn,
                storeClosingTimeForReturn
            } = timeSlots(selectedStore?.pc, storesData, pickupDay, returnDay);

            if (startDate && storeOpeningTimeForPickup && storeClosingTimeForPickup) {
                const leadTime = getLeadTime(cart);
                const tomorrow = tommorrowDate();
                let storeClosingTime = moment(storeClosingTimeForPickup, 'hh:mm A');
                let storeOpeningTime = moment(storeOpeningTimeForPickup, 'hh:mm A');
                let formattedStartDate = moment(startDate).format('LL');
                let formattedCurrentDate = moment().format('LL');
                let pickupTimeSlots = [];
                if (moment(formattedStartDate).isSame(formattedCurrentDate)) {
                    pickupTimeSlots = getTodayCondition(storeClosingTime, storeOpeningTime, leadTime, currentOffSet);
                }
                // TOMORROW
                else if (moment(formattedStartDate).isSame(tomorrow)) {
                    pickupTimeSlots = getTomorrowCondition(storeOpeningTime, storeClosingTime, leadTime, currentOffSet);
                } else {
                    // Timeslots for T+1 dates (no lead time added)
                    const currentTime = makeTimeInterval();
                    const date1 = new Date(currentTime);
                    const date2 = new Date(currentOffset);
                    let estOffSet = (Math.abs(date1 - date2) / 36e5) * 3600;
                    if (date1 > date2) {
                        estOffSet = '-' + estOffSet;
                    }
                    const startTimeWithOffset = storeOpeningTime.clone().add(estOffSet, 'seconds');
                    const roundOff = makeTimeInterval(startTimeWithOffset);
                    if (roundOff > storeOpeningTime) {
                        pickupTimeSlots = getTimeStops(roundOff, storeClosingTime);
                    } else {
                        pickupTimeSlots = getTimeStops(storeOpeningTime, storeClosingTime);
                    }
                }
                const returnDateWithTime = endDate ? new Date(endDate) : new Date();
                const returnDay = returnDateWithTime?.getDay();
                let returnTimeSlots = [];
                if (returnDay && storeOpeningTimeForReturn && storeClosingTimeForReturn) {
                    returnTimeSlots = getTimeStops(storeOpeningTimeForReturn, storeClosingTimeForReturn);
                }
                cartDispatch({ type: SET_PICKUP_TIME_SLOTS, pickUpTimeSlots: pickupTimeSlots });
                cartDispatch({ type: SET_RETURN_TIME_SLOTS, returnTimeSlots });
                return { pickupTimeSlots, returnTimeSlots };
            }
            return {};
        } catch (error) {
            logError(error, false, 'getPickupSlotsFromSelectedStore');
            return {};
        }
    };

    return {
        validateTimezoneAPI,
        validateDateSlots,
        getChronosStores,
        computeChronosStoreWithAVS,
        updateSourceAndLocationSearchRadius
    };
};

export default useCheckoutNavigate;
