import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { CollapsedView } from '../../global/modules/stepForm';
import EditOptionalPlans from './EditOptionalPlans';
import ReadOnlyOptionalPlans from './ReadOnlyOptionalPlans';
import { useCartState } from '../../../contexts/cart';
import { useUserContext } from '../../../aem-core-components/context/UserContext';
import { useCheckAuthorityType, useCheckUser } from '../../../hooks/useCheckUser';
import { keyStatusMap, shouldFuelChargeBeChecked } from '../checkoutUtils';
import {
    SET_OPTIONAL_PLANS_CHECKED,
    SET_OPTIONAL_PLANS_CHECKBOX_VISIBLE
} from '../../../aem-core-components/actions/constants';
import axiosInstance from '../../../api/axiosDotComInstance';
import { checkoutDatalocator } from '../checkoutAndOrderSummary/dataLocators';
import classes from '../checkoutAndOrderSummary/checkoutAndOrderSummary.css';
import { getURLParams, isPaymentSectionVisited } from '../../global/utils/commonUtils';
import { USER_TYPE } from '../../../constants/userDetailsConstants';
import { API_CONFIG } from '../../../constants/apiConfig';
import { AUTHORITY_TYPE } from '../../global/constants';

const OptionalPlans = props => {
    const [isRPPCheckboxVisible, setIsRPPCheckboxVisible] = useState(true);
    const [isFuelCheckboxVisible, setIsFuelCheckboxVisible] = useState(true);
    const intl = useIntl();
    const [userState] = useUserContext();
    const { userProfile } = userState;
    const { currentStep, handleStepChange, formStep, isCustomAccount } = props;
    const stepNumber = formStep.OPTIONAL_PLANS;
    const [{ userAccount, cart, clickedMakeChanges }, dispatch] = useCartState();
    const userType = useCheckUser();
    const { fuelCharges, rppCharges } = cart?.estimatesResponse?.estimate?.totals || {};
    const { GET_OPTIONAL_PLANS } = API_CONFIG;
    const authorityType = useCheckAuthorityType();
    const isP2PUser = authorityType === AUTHORITY_TYPE.P2P;
    useEffect(() => {
        if (clickedMakeChanges || !isPaymentSectionVisited()) {
            if (isP2PUser) {
                getOptionPlans();
            } else {
                processOptionalPlans();
            }
        }
    }, [userAccount?.accountNumber, rppCharges, fuelCharges]);

    const getOptionPlans = async () => {
        try {
            const { data } = await axiosInstance.get(
                `${GET_OPTIONAL_PLANS}/${userAccount?.accountNumber}/site-settings?keyId=RPPcheck,PFOcheck`
            );
            const optionalPlanDefaultCheckStatus = keyStatusMap(data?.data?.siteSettingDetails);
            processOptionalPlans(optionalPlanDefaultCheckStatus);
        } catch (error) {
            logError(error, false, 'getOptionPlans');
        }
    };

    const processOptionalPlans = (optionalPlanDefaultCheckStatus = {}) => {
        const chargeRPP = userProfile?.accounts?.find(
            account => account?.account === userAccount?.accountNumber
        )?.chargeRPP;
        const isRppChecked = setRPPChecked(chargeRPP, optionalPlanDefaultCheckStatus);
        const isFuelChargeChecked = shouldFuelChargeBeChecked(fuelCharges, optionalPlanDefaultCheckStatus);
        dispatch({
            type: SET_OPTIONAL_PLANS_CHECKED,
            initialRppSelection: isRppChecked,
            initialFuelChargeSelection: isFuelChargeChecked,
            isRPPChecked: isRppChecked,
            isFuelChargeChecked: isFuelChargeChecked
        });
    };

    useEffect(() => {
        if (parseInt(fuelCharges) === 0 || !fuelCharges) {
            setIsFuelCheckboxVisible(false);
        } else {
            setIsFuelCheckboxVisible(true);
        }
    }, [fuelCharges]);
    useEffect(() => {
        dispatch({
            type: SET_OPTIONAL_PLANS_CHECKBOX_VISIBLE,
            isRPPCheckboxVisible: isRPPCheckboxVisible,
            isFuelCheckboxVisible: isFuelCheckboxVisible
        });
    }, [isRPPCheckboxVisible, isFuelCheckboxVisible, userAccount?.accountNumber]);

    const setRPPChecked = (chargeRPP, defaultCheckStatus = {}) => {
        if (userType === USER_TYPE.CREDIT) {
            const rppStatus = defaultCheckStatus['RPPcheck'];
            if (parseInt(rppCharges) > 0 && rppCharges) {
                if (chargeRPP) {
                    setIsRPPCheckboxVisible(true);
                    return isP2PUser ? rppStatus : true;
                } else {
                    setIsRPPCheckboxVisible(false);
                    return isP2PUser ? rppStatus : false;
                }
            } else {
                setIsRPPCheckboxVisible(false);
                return false;
            }
        } else {
            if (parseInt(rppCharges) > 0 && rppCharges) {
                setIsRPPCheckboxVisible(true);
                return true;
            } else {
                setIsRPPCheckboxVisible(false);
                return false;
            }
        }
    };

    return (
        <div className={classes.root}>
            {currentStep < stepNumber && (
                <CollapsedView
                    stepNumber={stepNumber}
                    stepTitle={intl.formatMessage({ id: 'checkout:optional-plans-title' })}
                    testId={checkoutDatalocator.checkout_optionplans_link_testid}
                />
            )}
            {currentStep === stepNumber && (
                <EditOptionalPlans
                    currentStep={currentStep}
                    formStep={formStep}
                    currentTitle={intl.formatMessage({ id: 'checkout:optional-plans-title' })}
                    handleStepChange={handleStepChange}
                    testId={checkoutDatalocator.checkout_optionplans_link_testid}
                    isRPPCheckboxVisible={isRPPCheckboxVisible}
                    isCustomAccount={isCustomAccount}
                    isFuelCheckboxVisible={isFuelCheckboxVisible}
                />
            )}
            {currentStep > stepNumber && (
                <ReadOnlyOptionalPlans
                    testId={checkoutDatalocator.checkout_optional_stage_edit_testid}
                    handleStepChange={handleStepChange}
                    formStep={formStep}
                />
            )}
        </div>
    );
};

export default OptionalPlans;
