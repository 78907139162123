import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { CREDIT_CUSTOM_ACCOUNT_INFO } from '../../../../../aem-core-components/actions/constants';
import { useCartState } from '../../../../../contexts/cart';
import { useAnalyticsContext } from '../../../../../config/GoogleTagManagerEvents';
import { useUserContext } from '../../../../../aem-core-components/context/UserContext';
import { getProducts } from '../../../../../constants/analyticsConstants/getProducts';
import Button from '../../../../global/atoms/button/button';
import Input from '../../../../global/atoms/input/Input';
import { getURLParams, isPaymentSectionVisited } from '../../../../global/utils/commonUtils';
import { VARIABLE_CONFIG } from '../../../../../constants/analyticsConstants/Variables';
import './editCustomAccountsDetails.scss';
import { logError } from '../../../../global/utils/logger';
import { useFilterState } from '../../../../cap';

const CustomFormFields = props => {
    const { handleStepChange, formStep, onFormStateChange, formState, customBillingFields } = props;
    const [{ userAccount, cart, consumables, clickedMakeChanges }, dispatch] =
        useCartState();
    const [{viewCart, startDate, endDate}, filterDispatch] = useFilterState();

    const intl = useIntl();
    const [errorObj, setErrorObj] = useState({});
    const [isError, setIsError] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [userState] = useUserContext();
    const { userProfile } = userState;
    const { sendEventsForEcommerceCheckout, sendEventsForEcommerceCheckoutOption, sendEventsForUpdateVirtualPath } =
        useAnalyticsContext();
    const params = getURLParams();

    customBillingFields?.sort((a, b) => {
        return a?.displaySequence - b?.displaySequence;
    });
    const callEvents = () => {
        const datesObj = {
            startDate,
            endDate
        };
        if (!isPaymentSectionVisited() || clickedMakeChanges) {
            sendEventsForEcommerceCheckout(5, getProducts(cart, datesObj, false, consumables));
        }
    };

    useEffect(() => {
        callEvents();
    }, []);

    useEffect(() => {
        if (isSubmit) {
            dispatch({
                type: CREDIT_CUSTOM_ACCOUNT_INFO,
                customBillingFields: {
                    ...formState
                }
            });
        handleStepChange(formStep.SUBMIT_RESERVATION);
        }
    }, [isSubmit]);

    const handleSaveAndContinue = () => {
        setIsSubmit(true);
        try {
            if (!isPaymentSectionVisited() || clickedMakeChanges) {
                sendEventsForEcommerceCheckoutOption(5, {
                    accountName: userAccount?.accountName
                });
                sendEventsForUpdateVirtualPath(
                    `${VARIABLE_CONFIG.VIRTUAL_PAGE_TITLE.CHECKOUT_ADDITIONAL_INFO}`,
                    `/${
                        localStorage.getItem('companyID') == 2
                            ? VARIABLE_CONFIG.REGION.CANADA
                            : VARIABLE_CONFIG.REGION.US
                    }/${VARIABLE_CONFIG.VIRTUAL_PAGE_URL.CUSTOM_ACCOUNT_ADDITIONAL_INFO}`
                );
            }
        } catch (error) {
            logError(error, false, 'handleSaveAndContinue');
        }
    };
    const handleInputChange = e => {
        setIsSubmit(false);
        onFormStateChange({
            ...formState,
            [e.target.name]: {
                value: e.target.value,
                number: customBillingFields?.find(item => item.identifier === e.target.name)?.displaySequence
            }
        });
    };
    const renderBody = () => {
        return customBillingFields?.map(item => {
            return (
                <Input
                    name={item?.identifier}
                    label={item?.label}
                    value={formState[item?.identifier]?.value}
                    handleInputChange={handleInputChange}
                    placeholder={`Enter ${(item?.label).toLowerCase()}`}
                    maxLength={item?.maxSize}
                    inputAriaLabel={`Enter ${item?.label}`}
                    errorMsg={null}
                />
            );
        });
    };
    return (
        <>
            <h6 className="account-heading">{intl.formatMessage({ id: 'checkout:Disney-heading' })}</h6>
            <p className="account-message">{intl.formatMessage({ id: 'checkout:custom-field-message' })}</p>
            {renderBody()}
            <div className="saveAndContinueBtn">
                <Button
                    type="button"
                    data-testid={'btnID'}
                    onClick={handleSaveAndContinue}
                    buttonAriaLabel={intl.formatMessage({ id: 'checkout:save-continue-cta' })}
                    className="button button-primary button-block">
                    {intl.formatMessage({ id: 'checkout:save-continue-cta' })}
                </Button>
            </div>
        </>
    );
};
export default CustomFormFields;
