import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { useAnalyticsContext } from '../../../config/GoogleTagManagerEvents';
import useP2PAnalytics from '../../../hooks/analytics/useP2PAnalytics';
import useReservation from '../../checkoutv2/hooks/useReservation';
import { useCookieValue } from '../../../aem-core-components/utils/hooks';
import { useQuotesState } from '../../quotes/context';
import { checkIsEditQuoteFlow } from '../../global/utils/commonUtils';
import { logError } from '../../global/utils/logger';
import TransmitOrSaveButtons from './TransmitOrSaveButtons';
import TransmitOrSaveModals from './TransmitOrSaveModals';
import { SET_IS_TRANSMITTED, SET_TRANSMITTED_QUOTE_ID, SET_SALESFORCE_QUOTE_ID } from '../../quotes/actionTypes/actionTypes';
import { SAVE_FOR_LATER, TRANSMIT_NOW, TRANSMIT_STATUS_FALSE } from '../constants';
import { EVENT_P2P_NAMES_CONFIG } from '../../../constants/analyticsConstants/ProcureToPay';
import { STORAGE_CONFIG } from '../../../constants/storageConfig';
import { ERROR, SUCCESS } from '../../global/constants';
import './TransmitOrSave.scss';

const TransmitOrSaveMain = ({ selectedTransmitOrSave }) => {
    const [, quoteDispatch] = useQuotesState();
    const [{ createOrTransmitQuote, editOrSaveQuote, resetDetailsAfterQuoteSaveOrTransmit }] = useReservation();
    const [saveOrTransmitAPIStatus, setSaveOrTransmitAPIStatus] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [qouteTransmitStatus, setQuoteTransmitStatus] = useState('');
    const isEditQuoteFlow = checkIsEditQuoteFlow();
    const { sendEventsForP2PApplication } = useAnalyticsContext();
    const [{ createPayloadTransmitQuotePageLoadActionAnalytics }] = useP2PAnalytics();
    const [punchoutRedirectURL] = useCookieValue(STORAGE_CONFIG.COOKIES.PUNCHOUT_REDIRECT_URL);

    /** This function will call either Transmit/Save Quote or Edit/Save Quote  */
    const handleTransmitOrSaveCTA = async () => {
        const action =
            selectedTransmitOrSave === TRANSMIT_NOW
                ? EVENT_P2P_NAMES_CONFIG.P2P_LINK_NAME_TRANSMIT_QUOTE
                : EVENT_P2P_NAMES_CONFIG.P2P_LINK_NAME_SAVE_QUOTE;
        try {
            let quoteResponse;
            setSaveOrTransmitAPIStatus(''); // Closing the failure modal
            quoteDispatch({ type: SET_IS_TRANSMITTED, payload: true });

            if (isEditQuoteFlow) {
                ({ data: quoteResponse } = await editOrSaveQuote());
            } else {
                ({ data: quoteResponse } = await createOrTransmitQuote());
            }
            // To store the quote id into session when API returns quoteResponse data
            if (quoteResponse?.data) {
                localStorage.setItem(
                    STORAGE_CONFIG.LOCAL_STORAGE.DISPLAY_QUOTE_ID,
                    quoteResponse?.data?.displayQuoteId
                );
            }
            /**
             * We are checking for transmitStatus 'F', which means only the quote is saved and not transmitted.
             * If its status is 'Y', then we will return to the success modal.
             * If transmitStatus is 'N', it is from Tradcentric.
             */
            if (quoteResponse?.data && quoteResponse?.data?.transmitStatus !== TRANSMIT_STATUS_FALSE) {
                // here in response display id is coming as rentalman quote id i.e 10029284737
                quoteDispatch({ type: SET_TRANSMITTED_QUOTE_ID, payload: quoteResponse?.data?.displayQuoteId });
                quoteDispatch({ type: SET_SALESFORCE_QUOTE_ID, payload: quoteResponse?.data?.sfQuoteId });
                setSaveOrTransmitAPIStatus(SUCCESS);
                if (selectedTransmitOrSave === TRANSMIT_NOW) {
                    sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.SUCCESSFULLY_TRANSMITTED_QUOTE_ID);
                    resetDetailsAfterQuoteSaveOrTransmit();
                    sendEventsForP2PApplication(
                        EVENT_P2P_NAMES_CONFIG.P2P_QUOTE_TRANSMITTED,
                        {
                            quote_id: quoteResponse?.data?.sfQuoteId, // salesforce ID
                            transmitted_id: quoteResponse?.data?.displayQuoteId?.slice(2) // rentalman ID
                        }
                    );
                    window.parent.location.href = punchoutRedirectURL;
                }
                else {
                    quoteDispatch({ type: SET_IS_TRANSMITTED, payload: false });
                }
            } else {
                setQuoteTransmitStatus(quoteResponse?.data?.transmitStatus);
                setSaveOrTransmitAPIStatus(ERROR);
                setIsOpen(true);
                quoteDispatch({ type: SET_IS_TRANSMITTED, payload: false });
            }
            sendEventsForP2PApplication(
                EVENT_P2P_NAMES_CONFIG.P2P_CREATE_QUOTE_SUCCESS,
                createPayloadTransmitQuotePageLoadActionAnalytics(action, quoteResponse?.data?.displayQuoteId)
            );
        } catch (error) {
            setSaveOrTransmitAPIStatus(ERROR);
            logError(error, false, 'createQuote or saveQuote API failed to load data');
            quoteDispatch({ type: SET_IS_TRANSMITTED, payload: false });
            sendEventsForP2PApplication(
                EVENT_P2P_NAMES_CONFIG.P2P_CREATE_QUOTE_SUCCESS,
                createPayloadTransmitQuotePageLoadActionAnalytics(action)
            );
        }
    };

    return (
        <>
            <TransmitOrSaveButtons
                selectedTransmitOrSave={selectedTransmitOrSave}
                handleTransmitOrSaveCTA={handleTransmitOrSaveCTA}
            />
            {saveOrTransmitAPIStatus === ERROR && (
                <TransmitOrSaveModals
                    isOpen={isOpen}
                    selectedTransmitOrSave={selectedTransmitOrSave}
                    handleTransmitOrSaveCTA={handleTransmitOrSaveCTA}
                    setIsOpen={setIsOpen}
                    setSaveOrTransmitAPIStatus={setSaveOrTransmitAPIStatus}
                    qouteTransmitStatus={qouteTransmitStatus}
                />
            )}
        </>
    );
};

TransmitOrSaveMain.propTypes = {
    selectedTransmitOrSave: PropTypes.string
};

TransmitOrSaveMain.defaultProps = {
    selectedTransmitOrSave: SAVE_FOR_LATER
};

export default memo(TransmitOrSaveMain);
