export const orderSummaryDataLocators = {
    miniCartOverlay_orderSummary_txt: 'miniCartOverlay_orderSummary_txt',
    miniCartOverlay_taxesFees_txt: 'miniCartOverlay_taxesFees_txt',
    miniCartOverlay_rentalSubtotal_lbl: 'miniCartOverlay_rentalSubtotal_lbl',
    miniCartOverlay_rentalSubtotal_dynvalue: 'miniCartOverlay_rentalSubtotal_dynvalue',
    miniCartOverlay_instorePickup_lbl: 'miniCartOverlay_instorePickup_lbl',
    miniCartOverlay_instorePickupFree_txt: 'miniCartOverlay_instorePickupFree_txt',
    miniCartOVerlay_roundTripDelivery_lbl: 'miniCartOVerlay_roundTripDelivery_lbl',
    miniCartOVerlay_roundTripDelivery_dynValue: 'miniCartOVerlay_roundTripDelivery_dynValue',
    miniCartOverlay_getEstimate_cta: 'miniCartOverlay_getEstimate_cta',
    miniCartOverlay_rpp_lbl: 'miniCartOverlay_rpp_lbl',
    miniCartOverlay_rpp_tooltip: 'miniCartOverlay_rpp_tooltip',
    miniCartOVerlay_rppBlank_value: 'miniCartOVerlay_rppBlank_value',
    miniCartOverlay_fcp_lbl: 'miniCartOverlay_fcp_lbl',
    miniCartOverlay_fcp_tooltip: 'miniCartOverlay_fcp_tooltip',
    miniCartOVerlay_fcpBlank_value: 'miniCartOVerlay_fcpBlank_value',
    miniCartOverlay_taxes_lbl: 'miniCartOverlay_taxes_lbl',
    miniCartOVerlay_taxesBlank_value: 'miniCartOVerlay_taxesBlank_value',
    miniCartOverlay_srp_lbl: 'miniCartOverlay_srp_lbl',
    miniCartOverlay_srp_tooltip: 'miniCartOverlay_srp_tooltip',
    miniCartOverlay_srp_dynValue: 'miniCartOverlay_srp_dynValue',
    miniCartOverlay_purchase_subtotal_lbl: 'miniCartOverlay_purchase_subtotal_lbl',
    miniCartOverlay_purchase_subtotal_dynValue: 'miniCartOverlay_purchase_subtotal_dynValue',
    miniCartOverlay_subtotal_lbl: 'miniCartOverlay_subtotal_lbl',
    miniCartOverlay_subtotal_dynValue: 'miniCartOverlay_subtotal_dynValue',
    miniCartOverlay_estimated_total_lbl: 'miniCartOverlay_estimated_total_lbl',
    miniCartOverlay_estimated_total_dynValue: 'miniCartOverlay_estimated_total_dynValue',
    miniCartOverlay_total_lbl: 'miniCartOverlay_total_lbl',
    miniCartOverlay_total_dynValue: 'miniCartOverlay_total_dynValue',
    orderSummary_tbd_cta: 'orderSummary_tbd_cta',
    miniCartOverlay_roundtrip_free_text: 'miniCartOverlay_roundtrip_free_text'
};

export const checkoutOrderSummaryDataLocators = {
    checkoutOrderSummary_orderSummary_txt: 'checkoutOrderSummary_orderSummary_txt',
    checkoutOrderSummary_taxesFees_txt: 'checkoutOrderSummary_taxesFees_txt',
    checkoutOrderSummary_rentalSubtotal_lbl: 'checkoutOrderSummary_rentalSubtotal_lbl',
    checkoutOrderSummary_rentalSubtotal_dynvalue: 'checkoutOrderSummary_rentalSubtotal_dynvalue',
    checkoutOrderSummary_instorePickup_lbl: 'checkoutOrderSummary_instorePickup_lbl',
    checkoutOrderSummary_instorePickupFree_txt: 'checkoutOrderSummary_instorePickupFree_txt',
    checkoutOrderSummary_roundTripDelivery_lbl: 'checkoutOrderSummary_roundTripDelivery_lbl',
    checkoutOrderSummary_roundTripDelivery_dynValue: 'checkoutOrderSummary_roundTripDelivery_dynValue',
    checkoutOrderSummary_getEstimate_cta: 'checkoutOrderSummary_getEstimate_cta',
    checkoutOrderSummary_rpp_lbl: 'checkoutOrderSummary_rpp_lbl',
    checkoutOrderSummary_rpp_tooltip: 'checkoutOrderSummary_rpp_tooltip',
    checkoutOrderSummary_rppBlank_value: 'checkoutOrderSummary_rppBlank_value',
    checkoutOrderSummary_fcp_lbl: 'checkoutOrderSummary_fcp_lbl',
    checkoutOrderSummary_fcp_tooltip: 'checkoutOrderSummary_fcp_tooltip',
    checkoutOrderSummary_fcpBlank_value: 'checkoutOrderSummary_fcpBlank_value',
    checkoutOrderSummary_taxes_lbl: 'checkoutOrderSummary_taxes_lbl',
    checkoutOrderSummary_taxesBlank_value: 'checkoutOrderSummary_taxesBlank_value',
    checkoutOrderSummary_srp_lbl: 'checkoutOrderSummary_srp_lbl',
    checkoutOrderSummary_srp_tooltip: 'checkoutOrderSummary_srp_tooltip',
    checkoutOrderSummary_srp_dynValue: 'checkoutOrderSummary_srp_dynValue',
    checkoutOrderSummary_purchase_subtotal_lbl: 'checkoutOrderSummary_purchase_subtotal_lbl',
    checkoutOrderSummary_purchase_subtotal_dynValue: 'checkoutOrderSummary_purchase_subtotal_dynValue',
    checkoutOrderSummary_subtotal_lbl: 'checkoutOrderSummary_subtotal_lbl',
    checkoutOrderSummary_subtotal_dynValue: 'checkoutOrderSummary_subtotal_dynValue',
    checkoutOrderSummary_estimated_total_lbl: 'checkoutOrderSummary_estimated_total_lbl',
    checkoutOrderSummary_estimated_total_dynValue: 'checkoutOrderSummary_estimated_total_dynValue',
    checkoutOrderSummary_total_lbl: 'checkoutOrderSummary_total_lbl',
    checkoutOrderSummary_total_dynValue: 'checkoutOrderSummary_total_dynValue',
    checkoutOrderSummary_tbd_cta: 'checkoutOrderSummary_tbd_cta',
    checkoutOrderSummary_roundtrip_free_text: 'checkoutOrderSummary_roundtrip_free_text'
};

export const quoteSummaryDataLocators = {
    quoteSummary_orderSummary_txt: 'quoteSummary_orderSummary_txt',
    quoteSummary_taxesFees_txt: 'quoteSummary_taxesFees_txt',
    quoteSummary_rentalSubtotal_lbl: 'quoteSummary_rentalSubtotal_lbl',
    quoteSummary_rentalSubtotal_dynvalue: 'quoteSummary_rentalSubtotal_dynvalue',
    quoteSummary_instorePickup_lbl: 'quoteSummary_instorePickup_lbl',
    quoteSummary_instorePickupFree_txt: 'quoteSummary_instorePickupFree_txt',
    quoteSummary_roundTripDelivery_lbl: 'quoteSummary_roundTripDelivery_lbl',
    quoteSummary_roundTripDelivery_dynValue: 'quoteSummary_roundTripDelivery_dynValue',
    quoteSummary_getEstimate_cta: 'quoteSummary_getEstimate_cta',
    quoteSummary_rpp_lbl: 'quoteSummary_rpp_lbl',
    quoteSummary_rpp_tooltip: 'quoteSummary_rpp_tooltip',
    quoteSummary_rppBlank_value: 'quoteSummary_rppBlank_value',
    quoteSummary_fcp_lbl: 'quoteSummary_fcp_lbl',
    quoteSummary_fcp_tooltip: 'quoteSummary_fcp_tooltip',
    quoteSummary_fcpBlank_value: 'quoteSummary_fcpBlank_value',
    quoteSummary_taxes_lbl: 'quoteSummary_taxes_lbl',
    quoteSummary_taxesBlank_value: 'quoteSummary_taxesBlank_value',
    quoteSummary_srp_lbl: 'quoteSummary_srp_lbl',
    quoteSummary_srp_tooltip: 'quoteSummary_srp_tooltip',
    quoteSummary_srp_dynValue: 'quoteSummary_srp_dynValue',
    quoteSummary_purchase_subtotal_lbl: 'quoteSummary_purchase_subtotal_lbl',
    quoteSummary_purchase_subtotal_dynValue: 'quoteSummary_purchase_subtotal_dynValue',
    quoteSummary_subtotal_lbl: 'quoteSummary_subtotal_lbl',
    quoteSummary_subtotal_dynValue: 'quoteSummary_subtotal_dynValue',
    quoteSummary_estimated_total_lbl: 'quoteSummary_estimated_total_lbl',
    quoteSummary_estimated_total_dynValue: 'quoteSummary_estimated_total_dynValue',
    quoteSummary_total_lbl: 'quoteSummary_total_lbl',
    quoteSummary_total_dynValue: 'quoteSummary_total_dynValue',
    quoteSummary_tbd_cta: 'orderSummary_tbd_cta',
    quoteSummary_roundtrip_free_text: 'quoteSummary_roundtrip_free_text'
};

export const equipmentDatalocator = {
    updateTotal_button_testid: 'updateTotal_button_testid'
};

export const summaryDetailsDatalocator = {
    tooltip_icon: 'tooltip_icon'
};
