import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';
import useConsumables from '../../../../hooks/useConsumables';
import { useCheckUser } from '../../../../hooks/useCheckUser';
import useCheckout from '../../../../hooks/useCheckout';
import useMedia from '../../../../hooks/useMedia';
import useRoundTrip from '../../../../hooks/useRoundTrip';
import useCheckoutAnalytics from '../../../../hooks/analytics/useCheckoutAnalytics';
import useUserData from '../../../../hooks/useUserData';
import { useFilterState } from '../../../cap';
import { useCartState } from '../../../../contexts/cart';
import { useAnalyticsContext } from '../../../../config/GoogleTagManagerEvents';
import Button from '../../../global/atoms/button';
import Alert from '../../../global/atoms/alert/alert';
import CardRadioButton from '../../../global/atoms/cardRadioButton';
import Input from '../../../global/atoms/input/Input';
import AlertIcon from '../../../../resources/images/alert-triangle-red.svg';
import { EditableView } from '../../../global/modules/stepForm';
import { logError, logWarning } from '../../../global/utils/logger';
import {
    formatMobileNumber,
    getURLParams,
    isPaymentSectionVisited
} from '../../../global/utils/commonUtils';
import { checkFulfillmentInstore, getStores } from '../../../checkoutv2/utils/editOrderDetailsFunctions';
import useFulfillmentHooks from '../../../checkoutv2/utils/useFulFillmentHooks';
import {
    continueHandler,
    validateSmonElse,
    validateSmonElseOnToggle
} from '../../../checkoutv2/utils/editModuleFunctions';
import { LoadingIndicator } from '../../../../aem-core-components';
import { isAfterStartTime } from '../../utils/checkoutUtils';
import HowToGetOrderD2C from '../../checkoutAndOrderSummary/HowToGetOrderD2C/HowToGetOrderD2C';
import HowToGetOrderDetails from './HowToGetOrderDetails';
import { PickUpandReturnTime } from '../../howToGetYourOrder/editHowToGetYourOrder/PickUpandReturnTime';
import './../howToGetOrder.scss';
import { cardData } from '../../checkoutAndOrderSummary/constants';
import { checkoutDatalocator } from '../../checkoutAndOrderSummary/dataLocators';
import { VARIABLE_CONFIG } from '../../../../constants/analyticsConstants/Variables';
import { getProducts } from '../../../../constants/analyticsConstants/getProducts';
import { USER_TYPE } from '../../../../constants/userDetailsConstants';
import {
    RESET_CONSUMABLES,
    SET_HOW_TO_GET_YOUR_ORDER_FIELDS,
    SET_VIEW_CART_FIELDS
} from '../../../../aem-core-components/actions/constants';
import { HOW_TO_GET_YOUR_ORDER, VIEW_CART } from '../../../../constants/cartConstants';

const EditHowToGetOrder = ({
    endDate,
    startDate,
    currentOffSet,
    handleStepChange,
    currentStep,
    formStep,
    isCustomAccount
}) => {
    const [
        {
            startDateSlots,
            endDateSlots,
            cart,
            howToGetYourOrderDetails,
            isLoading,
            consumables,
            isCheckoutEditResetComplete,
            pickUpTimeSlots,
            returnTimeSlots,
            orderSummaryDetails
        },
        dispatch
    ] = useCartState();
    const [{ viewCart, projectDetails }, filterDispatch] = useFilterState();
    const { sendEventsForEcommerceCheckout } = useAnalyticsContext();
    const [selectedValue, setSelectedValue] = useState(howToGetYourOrderDetails?.pickupType);
    const [selectedPickupType, setSelectedPickupType] = useState();
    const [selectedPickupTime, setSelectedPickupTime] = useState(howToGetYourOrderDetails?.selectedPickupTime);
    const [selectedReturnTime, setSelectedReturnTime] = useState(howToGetYourOrderDetails?.selectedReturnTime);
    const [isReturnError, setIsReturnError] = useState(false);
    const [someoneElse, setSomeoneElse] = useState({
        firstname: howToGetYourOrderDetails?.someoneElse?.firstname,
        lastname: howToGetYourOrderDetails?.someoneElse?.lastname,
        phone: howToGetYourOrderDetails?.someoneElse?.phone,
        email: howToGetYourOrderDetails?.someoneElse?.email
    });
    const phoneNumberDisclaimerOther = document.querySelector('.checkout')?.dataset?.phonedisclaimerother || '';
    const emailDisclaimerOther =
        document.querySelector('.checkout')?.dataset?.emaildisclaimerother ||
        'Please provide an active email address. This will be used to verify your pickup.';

    const [storesData, setStoresData] = useState({ data: [howToGetYourOrderDetails?.selectedStoreDetails] });
    const [smoneElseError, setSmoneElseError] = useState({
        firstname: '',
        lastname: '',
        phone: '',
        email: ''
    });
    const intl = useIntl();
    const isRoundTrip = userType !== USER_TYPE.GUEST && !viewCart?.isInStorePickup;
    const [rentalRevisionAlert, setRentalRevisionAlert] = useState(false);
    const [rentalAmountBeforeSlots, setRentalAmountBeforeSlots] = useState(0);
    const [isPickUpError, setIsPickUpError] = useState(false);
    const [{ getConsumables }] = useConsumables();
    const { isIn24Hrs } = useFulfillmentHooks();
    const [{ sendEventsForPickup }] = useCheckoutAnalytics();
    const { fetchTimeZoneAPI } = useRoundTrip();
    const params = getURLParams();
    const [{ getAssets }] = useUserData();
    const { checkoutErrorAnalyticsEvent, refreshCart } = useCheckout();
    const userType = useCheckUser();
    const assets = getAssets();

    useEffect(() => {
        const datesObj = {
            startDate,
            endDate
        };
        if (!isPaymentSectionVisited() && !params.TRANSUCCESS) {
            sendEventsForEcommerceCheckout(3, getProducts(cart, datesObj, false, consumables));
            dispatch({
                type: RESET_CONSUMABLES,
                consumables: {
                    data: {},
                    selected: {},
                    selectedRentalEquipment: {},
                    showRentalEquipmentScreen: false,
                    isRentalEquipmentsAdded: false,
                    saveAndContinue: false,
                    orderSummaryRentals: {},
                    selectedRentalQtyAndPrice: {
                        totalRentalQty: 0,
                        totalAddonPrice: 0,
                        totalAddonQty: 0,
                        totalRentalPrice: 0
                    }
                }
            });
        }
        clearInterval(viewCart?.checkPCInterval);
    }, []);

    // this useEffect recalculates the total if the sections are edited
    useEffect(() => {
        if (isCheckoutEditResetComplete) {
            refreshCart();
        }
    }, [isCheckoutEditResetComplete]);

    useEffect(() => {
        if (viewCart.isInStorePickup) {
            dispatch({
                type: SET_HOW_TO_GET_YOUR_ORDER_FIELDS,
                key: HOW_TO_GET_YOUR_ORDER.SELECTED_START_DATE,
                value: startDate
            });
        }
    }, [startDate]);

    useEffect(() => {
        if (viewCart.isInStorePickup) {
            dispatch({
                type: SET_HOW_TO_GET_YOUR_ORDER_FIELDS,
                key: HOW_TO_GET_YOUR_ORDER.SELECTED_END_DATE,
                value: endDate
            });
        }
    }, [endDate]);

    // to show and hide the rentalAmount alert in case of updated amount, not on firt load
    useEffect(() => {
        if (!rentalAmountBeforeSlots) {
            setRentalAmountBeforeSlots(orderSummaryDetails?.estimatedSubtotal);
        } else if (
            selectedReturnTime &&
            selectedPickupTime &&
            orderSummaryDetails?.estimatedSubtotal > rentalAmountBeforeSlots
        ) {
            setRentalRevisionAlert(true);
        } else {
            setRentalRevisionAlert(false);
        }
    }, [orderSummaryDetails?.estimatedSubtotal]);

    const checkoutErrorHandlingAnalytics = (error, field) => {
        checkoutErrorAnalyticsEvent(formStep, currentStep, error, field);
    };

    const updateAccountDetailsHandler = data => {
        setSelectedPickupType(data);
        if (data?.jobNumber === 2) {
            validateSmonElseOnToggle(someoneElse, setSmoneElseError, intl, checkoutErrorHandlingAnalytics);
        }
    };

    const checkPcAvailablity = (pc, addr1) => {
        const checkIfPcAvailable = async () => {
            if (cart?.items?.length > 0) {
                const { offset } = await fetchTimeZoneAPI(addr1);
                const currStoresData = await getStores(
                    viewCart,
                    startDate,
                    endDate,
                    projectDetails,
                    userType,
                    dispatch,
                    assets,
                    howToGetYourOrderDetails,
                    false,
                    viewCart?.isInStorePickup ? false : isIn24Hrs(startDate, offset)
                );
                for (let i in currStoresData?.data?.data) {
                    if (
                        pc === currStoresData?.data?.data[i]?.pc &&
                        checkFulfillmentInstore(currStoresData?.data?.data[i], startDate)
                    ) {
                        return;
                    }
                }
                handleChange(VIEW_CART.SHOW_INACTIVE_PC_ALERT, true);
                logWarning('Store not available');
            } else {
                clearInterval(viewCart?.checkPCInterval);
            }
        };
        clearInterval(viewCart?.checkPCInterval);
        const interval = setInterval(checkIfPcAvailable, 300000);
        handleChange(VIEW_CART.CHECK_PC_INTERVAL, interval);
    };

    const cardStyle = data => {
        return (
            <div className={'HTGO_accountCard'}>
                <span className={'HTGO_center'}>{data.content}</span>
            </div>
        );
    };

    const displaySearchResults = data => {
        return (
            <CardRadioButton
                key={data?.jobNumber}
                item={data}
                cardStyle={cardStyle}
                selectedValue={selectedValue}
                setSelectedValue={setSelectedValue}
                radioButtonAriaLabel={data.content}
                onPressRadioButton={updateAccountDetailsHandler}
                isAccount={false}
            />
        );
    };

    const handleSmonElse = e => {
        const { name, value } = e.target;
        if (name === 'phone') {
            const val = formatMobileNumber(value);
            setSomeoneElse(prevState => ({ ...prevState, [name]: val }));
            setSmoneElseError(prevState => ({ ...prevState, [name]: '' }));
        } else {
            setSomeoneElse(prevState => ({ ...prevState, [name]: value }));
            setSmoneElseError(prevState => ({ ...prevState, [name]: '' }));
        }
    };

    const validateForm = consumablesData => {
        const HowToGetOrderStep = formStep.HTGO;
        const OptionalPlansOrAddOnStep = HowToGetOrderStep + 1;
        if (!selectedPickupTime || !selectedReturnTime || !howToGetYourOrderDetails?.selectedStoreDetails?.pc) {
            //invalid
            handleStepChange(HowToGetOrderStep);
            return;
        }
        if (selectedPickupType?.jobNumber === 2 || selectedValue === 2) {
            const isValid = validateSmonElse(someoneElse, setSmoneElseError, intl, checkoutErrorHandlingAnalytics);
            if (isValid) {
                dispatch({
                    type: SET_HOW_TO_GET_YOUR_ORDER_FIELDS,
                    key: HOW_TO_GET_YOUR_ORDER.SOMEONE_ELSE,
                    value: {
                        firstname: someoneElse?.firstname?.trim(),
                        lastname: someoneElse?.lastname?.trim(),
                        phone: someoneElse?.phone,
                        email: someoneElse?.email
                    }
                });
                handleStepChange(OptionalPlansOrAddOnStep);
            } else {
                handleStepChange(HowToGetOrderStep);
            }
        } else {
            handleStepChange(OptionalPlansOrAddOnStep);
        }
    };

    const handleChange = (key, value) => {
        filterDispatch({ type: SET_VIEW_CART_FIELDS, key, value });
    };

    const onContinue = async () => {
        setIsPickUpError(false);
        if (viewCart?.isInStorePickup && !selectedPickupTime) {
            setIsPickUpError(true);
            logWarning(intl.formatMessage({ id: 'common:required-input-error' }));
            checkoutErrorHandlingAnalytics(
                intl.formatMessage({ id: 'common:required-input-error' }),
                VARIABLE_CONFIG.CHECKOUT_FIELD_NAMES.PICK_UP_TIME
            );
        } else if (viewCart?.isInStorePickup && !selectedReturnTime) {
            setIsReturnError(true);
            logWarning(intl.formatMessage({ id: 'common:required-input-error' }));
            checkoutErrorHandlingAnalytics(
                intl.formatMessage({ id: 'common:required-input-error' }),
                VARIABLE_CONFIG.CHECKOUT_FIELD_NAMES.RETURN_TIME
            );
        } else if (returnTimeSlots?.length === 0) {
            setIsReturnError(true);
            logWarning(intl.formatMessage({ id: 'common:required-input-error' }));
            checkoutErrorHandlingAnalytics(
                intl.formatMessage({ id: 'common:required-input-error' }),
                VARIABLE_CONFIG.CHECKOUT_FIELD_NAMES.RETURN_TIME
            );
        } else {
            const consumablesData = await getConsumables(howToGetYourOrderDetails?.selectedStoreDetails?.pc);
            dispatch({
                type: SET_HOW_TO_GET_YOUR_ORDER_FIELDS,
                key: HOW_TO_GET_YOUR_ORDER.SELECTED_RETURN_TIME,
                value: selectedReturnTime
            });
            dispatch({
                type: SET_HOW_TO_GET_YOUR_ORDER_FIELDS,
                key: HOW_TO_GET_YOUR_ORDER.SELECTED_PICKUP_TIME,
                value: selectedPickupTime
            });
            dispatch({
                type: SET_HOW_TO_GET_YOUR_ORDER_FIELDS,
                key: HOW_TO_GET_YOUR_ORDER.PICKUP_TYPE,
                value: selectedValue
            });
            if (viewCart?.isInStorePickup) {
                checkPcAvailablity(
                    howToGetYourOrderDetails?.selectedStoreDetails?.pc,
                    howToGetYourOrderDetails?.selectedStoreDetails?.street
                );
            }
            continueHandler(
                startDate,
                endDate,
                selectedPickupTime,
                selectedReturnTime,
                setIsReturnError,
                validateForm,
                consumablesData,
                intl,
                checkoutErrorHandlingAnalytics
            );
        }
        try {
            sendEventsForPickup(3, startDate, endDate);
        } catch (error) {
            logError(error, false, 'changeCUrrentStep');
        }
    };

    const handlePickUpTimeChange = selectedValue => {
        setSelectedPickupTime(selectedValue);
    };

    const handleReturnTimeChange = selectedValue => {
        setSelectedReturnTime(selectedValue);
    };
    const showRentalRevisionAlert = () => {
        if (isAfterStartTime(selectedPickupTime, selectedReturnTime) && rentalRevisionAlert) {
            return true;
        }
        return false;
    };

    const renderEditableView = () => {
        if (isRoundTrip) {
            return (
                <>
                    {isLoading && (
                        <div className={'orderSummaryLoader'}>
                            <LoadingIndicator />
                        </div>
                    )}
                    <HowToGetOrderDetails storesData={storesData} />
                    <HowToGetOrderD2C
                        handleStepChange={handleStepChange}
                        rentalStartDate={moment(startDate).format('MMM DD, YYYY')}
                        rentalEndDate={moment(endDate).format('MMM DD, YYYY')}
                        startDateSlots={startDateSlots}
                        endDateSlots={endDateSlots}
                        formStep={formStep}
                        checkoutErrorHandlingAnalytics={checkoutErrorHandlingAnalytics}
                    />
                </>
            );
        } else {
            return (
                <div>
                    {isLoading && (
                        <div className={'orderSummaryLoader'}>
                            <LoadingIndicator />
                        </div>
                    )}

                    <HowToGetOrderDetails storesData={storesData} />

                    <h6
                        className={'pickRetTimeHeading'}
                        data-testid={checkoutDatalocator.checkout_PickUpAndReturnTime_label_testid}>
                        {intl.formatMessage({ id: 'checkout:pickup-and-return-time' })}
                    </h6>
                    <>
                        <PickUpandReturnTime
                            isPickUpError={isPickUpError}
                            setIsPickUpError={setIsPickUpError}
                            pickUpTimeSlots={pickUpTimeSlots}
                            returnTimeSlots={returnTimeSlots}
                            selectedReturnTime={selectedReturnTime}
                            selectedPickupTime={selectedPickupTime}
                            isReturnError={isReturnError}
                            setIsReturnError={setIsReturnError}
                            setSelectedPickupTime={handlePickUpTimeChange}
                            setSelectedReturnTime={handleReturnTimeChange}
                            currentOffSet={currentOffSet}
                            selectedStartDate={startDate}
                            selectedEndDate={endDate}
                            checkoutErrorHandlingAnalytics={checkoutErrorHandlingAnalytics}
                        />
                        {showRentalRevisionAlert() && (
                            <div className="rentalUpdateAlert">
                                <Alert
                                    className="alert alert-withmargin"
                                    type={'warning'}
                                    message={intl.formatMessage({
                                        id: 'How-To-Get-Your-Order:order-total-revision-warning-inStore'
                                    })}
                                    icon={<AlertIcon tabIndex={-1} aria-hidden={true} />}
                                />
                            </div>
                        )}
                    </>

                    <>
                        <h6
                            className={'whoWillPickRetTimeHeading'}
                            data-testid={checkoutDatalocator.checkout_whowillpickupitems_labels_testid}>
                            {intl.formatMessage({ id: 'checkout:pickup-type-heading' })}
                        </h6>
                        <div className={'HTGO_cardContainerRoot'}>
                            <div
                                className={'HTGO_cardContainer'}
                                data-testid={checkoutDatalocator.checkout_iwillpickitup_radiobutton_testid}>
                                {displaySearchResults(cardData[0])}
                            </div>
                            <div
                                className={'HTGO_cardContainer'}
                                data-testid={checkoutDatalocator.checkout_someoneelsewill_radiobutton_testid}>
                                {displaySearchResults(cardData[1])}
                            </div>
                        </div>
                    </>

                    {(selectedPickupType?.jobNumber === 2 || selectedValue === 2) && (
                        <div className={'marginTopPickup'}>
                            <Input
                                name="firstname"
                                label={intl.formatMessage({ id: 'checkout:htgo-pickup-type-first-name' })}
                                value={someoneElse.firstname}
                                handleInputChange={handleSmonElse}
                                inputAriaLabel={intl.formatMessage({ id: 'checkout:htgo-pickup-type-first-name' })}
                                inputAriaRequired={true}
                                required={true}
                                errorMsg={smoneElseError.firstname}
                                dataTestId={checkoutDatalocator.checkout_firstNameofpickUpcontact_txtfield_testid}
                            />
                            <Input
                                name="lastname"
                                label={intl.formatMessage({ id: 'checkout:htgo-pickup-type-last-name' })}
                                value={someoneElse.lastname}
                                handleInputChange={handleSmonElse}
                                errorMsg={smoneElseError.lastname}
                                required={true}
                                inputAriaLabel={intl.formatMessage({ id: 'checkout:htgo-pickup-type-last-name' })}
                                dataTestId={checkoutDatalocator.checkout_lastnameofpickupcontact_txtfield_testid}
                            />
                            <Input
                                name="phone"
                                label={intl.formatMessage({ id: 'checkout:phone-number' })}
                                value={someoneElse.phone}
                                errorMsg={smoneElseError.phone}
                                handleInputChange={handleSmonElse}
                                inputAriaLabel={intl.formatMessage({ id: 'checkout:phone-number' })}
                                inputAriaRequired={true}
                                required={true}
                                dataTestId={checkoutDatalocator.checkout_phoneNumber_txtfield_testid}
                                disclaimerMsg={phoneNumberDisclaimerOther}
                            />
                            <Input
                                name="email"
                                label={intl.formatMessage({ id: 'checkout:email-address' })}
                                value={someoneElse.email}
                                errorMsg={smoneElseError.email}
                                handleInputChange={handleSmonElse}
                                inputAriaLabel={intl.formatMessage({ id: 'checkout:email-address' })}
                                inputAriaRequired={true}
                                required={true}
                                dataTestId={checkoutDatalocator.checkout_emailAddress_txtfield_testid}
                                disclaimerMsg={emailDisclaimerOther}
                            />
                        </div>
                    )}

                    <div className={'HTGO_continueBtn'}>
                        <Button
                            type="button"
                            onClick={onContinue}
                            className="button button-primary button-block"
                            buttonAriaLabel={intl.formatMessage({ id: 'checkout:save-continue-cta' })}
                            data-testid={checkoutDatalocator.checkout_howtogetyourorder_Continue_cta_testid}>
                            {intl.formatMessage({ id: 'checkout:save-continue-cta' })}
                        </Button>
                    </div>
                </div>
            );
        }
    };

    return (
        <EditableView
            children={renderEditableView()}
            currentStep={currentStep}
            currentTitle={intl.formatMessage({ id: 'checkout:how-to-get-your-order-title' })}
            testId={checkoutDatalocator.checkout_howtogetyourorder_link_testid}
        />
    );
};

export default EditHowToGetOrder;
