import { func, shape, object } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Button from '../../../../../main/aem-core-components/components/Button';
import { Price } from '../../../../aem-core-components';
import { checkoutDatalocator } from '../../checkoutAndOrderSummary/dataLocators';
import '../accessoriesAndAddons.scss';
import { useCartState } from '../../../../contexts/cart';
import { useDidMount } from '../../../../hooks/useDidMount';

const RentalFooter = ({ saveChanges, onCloseClick, selectedItemsDetails, selectedItems }) => {
    const intl = useIntl();
    const [{ consumables }, dispatch] = useCartState();
    const [buttonLabel, setButtonLabel] = useState(intl.formatMessage({ id: 'common:cancel' }));
    const didMount = useDidMount();

    useEffect(() => {
        if (didMount) {
            if (Object.keys(consumables?.selected).length > 0) {
                setButtonLabel(intl.formatMessage({ id: 'addons:update-cta-label' }));
            } else if (selectedItemsDetails?.totalQuantity == 0 && Object.keys(consumables?.selected).length == 0) {
                setButtonLabel(intl.formatMessage({ id: 'common:cancel' }));
            } else if (selectedItemsDetails?.totalQuantity === 1) {
                let addLabel = `Add ` + `${selectedItemsDetails?.totalQuantity}` + ` item`;
                setButtonLabel(addLabel);
            } else if (selectedItemsDetails?.totalQuantity > 1) {
                let addLabel = `Add ` + `${selectedItemsDetails?.totalQuantity}` + ` items`;
                setButtonLabel(addLabel);
            }
        }
    }, [selectedItemsDetails?.totalQuantity, selectedItems?.rentals, selectedItems?.addons]);

    return (
        <>
            {selectedItemsDetails?.totalPrice > 0 ? (
                <div className="selected-items">
                    <span>Selected items:</span>
                    <span>
                        <Price
                            currencyCode="USD"
                            value={parseFloat(selectedItemsDetails?.totalPrice)}
                            minFractionDigits={2}
                            maxFractionDigits={2}
                        />
                    </span>
                </div>
            ) : (
                ''
            )}
            <Button
                type="button"
                className={'button button-primary button-block'}
                onClick={buttonLabel === intl.formatMessage({ id: 'common:cancel' }) ? onCloseClick : saveChanges}
                buttonAriaLabel={`${intl.formatMessage({ id: 'account:ac-common-click-here-msg' })} ${buttonLabel} `}
                data-testid={checkoutDatalocator.checkout_rental_equipment_submit}>
                {buttonLabel}
            </Button>
        </>
    );
};

export default RentalFooter;

RentalFooter.propsType = {
    saveChanges: func,
    onCloseClick: func,
    selectedItems: shape({ rentals: object })
};

RentalFooter.defaultProps = {
    saveChanges: () => {},
    onCloseClick: () => {},
    selectedItems: {
        rentals: {}
    }
};
