import React, { memo, useState, useRef, useEffect } from 'react';
import { bool, func } from 'prop-types';
import moment from 'moment';
import { useIntl } from 'react-intl';
import isObjectWithKeys from '../../../../aem-core-components/utils/isObjectWithKeys';
import ToggleTabs from '../../../../components/global/modules/ToggleTabs/ToggleTabs';
import Button from '../../../global/atoms/button';
import StoreCard from '../StoreCard/StoreCard';
import StoreCardShimmer from '../StoreCard/StoreCardShimmer';
import { checkoutDatalocator } from '../../../checkoutv2/checkoutAndOrderSummary/dataLocators';
import { useCartState } from '../../../../contexts/cart';
import { useFilterState } from '../../../cap';
import { filterStores } from '../../../checkoutv2/utils/editOrderDetailsFunctions';
import './storeCard.scss';
import { STORE_VARIANT } from '../../../cap/constants';
import { MEDIA_TYPE } from '../../../../constants/screenConstants';

const Stores = props => {
    const intl = useIntl();
    const {
        isBestMatch,
        setIsBestMatch,
        handleToggle,
        selectedStoreValue,
        setSelectedStoreValue,
        handleChooseThisStoreClick,
        storesData,
        onStoreDetailClick,
        onPressRadioButton,
        nextAvailableStartDate,
        variant,
        mediaType,
        showAvailableStartDate,
        className,
        companyId,
        showShimmer,
        localUnavailableCartItemsPerPc
    } = props;
    const [{ howToGetYourOrderDetails, cart }] = useCartState();
    const [{ startDate }] = useFilterState();
    let date = moment(startDate);
    const isMobile = mediaType === MEDIA_TYPE.MOBILE;
    const isTabletView = mediaType === MEDIA_TYPE.TABLET;
    const currentDatePlusOne = moment().add(1, 'days');
    const [storesNearMeOnly, setStoresNearMeOnly] = useState(false);
    const [closestStoreID, setClosestStoreID] = useState('');
    const [isSticky, setIsSticky] = useState(false);
    const storeHeaderModalRef = useRef(null);
    const refs = storesData?.data.reduce((acc, value) => {
        acc[value.pc] = React.createRef();
        return acc;
    }, {});
    const showUnavailableItemsBanner = variant === STORE_VARIANT.CAP_HEADER && isObjectWithKeys(localUnavailableCartItemsPerPc);

    useEffect(() => {
        if (variant === STORE_VARIANT.CAP_HEADER && mediaType === MEDIA_TYPE.DESKTOP) {
            storeHeaderModalRef?.current?.addEventListener('scroll', handleIsSticky);
            return () => {
                if (storeHeaderModalRef?.current) {
                    storeHeaderModalRef?.current?.removeEventListener('scroll', handleIsSticky);
                }
            };
        }
    }, [variant]);
    const handleIsSticky = e => {
        const scrollTop = storeHeaderModalRef?.current?.scrollTop;
        scrollTop > 0 ? setIsSticky(true) : setIsSticky(false);
    };
    useEffect(() => {
        let minIndex = 0;
        const BMData = filterStores(howToGetYourOrderDetails?.bestMatchStoresData, startDate);
        if (date.isSameOrBefore(currentDatePlusOne)) {
            if (BMData?.length === 1 && BMData[0]?.isLastResortBranch === true) {
                setStoresNearMeOnly(true);
                setIsBestMatch(false);
            }
            const fulfillmentCheck = BMData?.every(item => {
                if (item?.fulfillmentPercent === 1) {
                    return false;
                }
                return true;
            });
            if (BMData?.length > 1 && fulfillmentCheck) {
                setStoresNearMeOnly(true);
                setIsBestMatch(false);
            }
        }
        if (BMData?.length === 0) {
            setStoresNearMeOnly(true);
            setIsBestMatch(false);
        }
        if (storesData?.data?.length > 0) {
            let minValue = storesData?.data[0]?.drivingDistanceFromJobsite;
            storesData?.data.map((item, index) => {
                if (Number(item?.drivingDistanceFromJobsite) < Number(minValue)) {
                    minValue = item?.drivingDistanceFromJobsite;
                    minIndex = index;
                }
            });
            if (storesData?.data[0]?.pc !== storesData?.data[minIndex]?.pc) {
                setClosestStoreID(storesData?.data[minIndex]?.pc || '');
            }
        }
    }, [handleChooseThisStoreClick]);
    useEffect(() => {
        if (selectedStoreValue) {
            const ref = refs?.[selectedStoreValue]?.current;
            if (ref && typeof ref?.scrollIntoView === 'function') {
                ref?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                });
            }
        }
    }, [selectedStoreValue]);
    const renderStores = (data, index, itemsForStore) => {
        return (
            <StoreCard
                isStoresNearMe={!isBestMatch}
                itemNumber={index + 1}
                item={data}
                closestStoreId={closestStoreID}
                selectedStoreValue={selectedStoreValue}
                setSelectedStoreValue={setSelectedStoreValue}
                onStoreDetailClick={onStoreDetailClick}
                onPressRadioButton={onPressRadioButton}
                nextAvailableStartDate={nextAvailableStartDate}
                variant={variant}
                showAvailableStartDate={showAvailableStartDate}
                companyId={companyId}
                unavailableProducts={itemsForStore}
                showItemAvability={showUnavailableItemsBanner}
            />
        );
    };
    const renderStoreCardShimmer = () => (
        <>
            <StoreCardShimmer />
            <StoreCardShimmer />
            <StoreCardShimmer />
        </>
    );
    const renderStoresBody = () => (
        <>
            {!storesNearMeOnly && variant !== STORE_VARIANT.CAP_HEADER && (
                <ToggleTabs
                    toggle1Title={intl.formatMessage({ id: 'choose-store:best-match' })}
                    toggle2Title={intl.formatMessage({ id: 'choose-store:stores-near-me' })}
                    isSelected={isBestMatch}
                    toggleHandler={handleToggle}
                    toggle1TestId={checkoutDatalocator.checkout_bestMatch}
                    toggle2TestId={checkoutDatalocator.checkout_store_near_me}
                    isBoxShadowRequired={true}
                />
            )}
            {storesData?.data?.map((data, index) => {
                const itemsForStore = localUnavailableCartItemsPerPc?.[data?.pc] || [];
                return (
                    <div key={data?.pc} ref={refs[data?.pc]} className="mobile-view-list-item">
                        {renderStores(data, index, itemsForStore)}
                    </div>
                );
            })}
        </>
    );
    const renderContentOrShimmer = () => {
        return showShimmer ? renderStoreCardShimmer() : renderStoresBody();
    };
    return (
        <>
            {isMobile ? (
                <div className={`${className || ''} mobile-view-content`}>
                    <h5
                        tabIndex={0}
                        className="mobile-view-content-title"
                        data-testid={checkoutDatalocator.checkout_choose_a_store}>
                        {intl.formatMessage({ id: 'checkout:choose-a-store' })}
                    </h5>
                    {renderContentOrShimmer()}
                </div>
            ) : (
                <div className={`${className || ''} modal-map-right`}>
                    <div className={`modal-map-rightheader ${isSticky ? 'modal-map-rightheader-scroll' : ''}`}>
                        <h5
                            id="chooseStoremodal"
                            tabIndex={0}
                            className="modal-map-righttitle"
                            data-testid={checkoutDatalocator.checkout_choose_a_store}>
                            {intl.formatMessage({ id: 'checkout:choose-a-store' })}
                        </h5>
                    </div>
                    <div className="modal-map-rightbody modal-map-rightbody-background" ref={storeHeaderModalRef}>
                        {renderContentOrShimmer()}
                    </div>
                    {!(isTabletView && variant == STORE_VARIANT.CAP_HEADER) && (
                        <div className="modal-map-rightfooter">
                            {showShimmer ? (
                                <div className="choose-this-store__shimmer shimmer"></div>
                            ) : (
                                <Button
                                    type="button"
                                    className="button button-primary button-block"
                                    onClick={handleChooseThisStoreClick}
                                    buttonAriaLabel={intl.formatMessage({ id: 'checkout:choose-this-store' })}
                                    data-testid={checkoutDatalocator.checkout_choose_this_store}>
                                    {intl.formatMessage({ id: 'checkout:choose-this-store' })}
                                </Button>
                            )}
                        </div>
                    )}
                </div>
            )}
        </>
    );
};
Stores.defaultProps = {
    setIsBestMatch: () => { },
    showAvailableStartDate: false,
    showShimmer: false
};
Stores.propTypes = {
    setIsBestMatch: func,
    showAvailableStartDate: bool,
    showShimmer: bool
};
export default memo(Stores);